$base-class: '.el-FooterNavigation';

@mixin useFooterNavigationVariants($variant) {
  @include useVariants($base-class, $variant) {
    @content;
  }
}

#{$base-class} {
  --color: #{$color-white};
  --border-color: transparent;

  &__items {
    @include interpolate(margin-top, -5px, -10px);
  }

  &__item {
    @include interpolate(margin-top, 5px, 10px);

    &__link {
      @include font-h3;
      color: var(--color);
      line-height: 1;
      position: relative;

      &:after {
        @include transition(background-color);
        content: "";
        position: absolute;
        bottom: .1em;
        left: 0;
        right: 0;
        height: .05em;
        background-color: var(--border-color);
      }

      &:hover {
        --border-color: #{rgba($color-white, .25)};
      }
    }
  }
}