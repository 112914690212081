$base-class: '.atom-ButtonPrimary';

@mixin useButtonPrimaryVariants($variant) {
  @include useVariants($base-class, $variant) {
    @content;
  }
}

#{$base-class} {
  $self: &;
  --background-color: #{$color-secondary};
  --color: #{$color-white};
  --border-color: #{$color-secondary};
  @include interpolate((padding-left, padding-right), 15px, 30px);
  @include interpolate((padding-top, padding-bottom), 8px, 16px);
  @include font-body-small;
  @include transition((color, background-color, border-color));
  border-radius: 2px;
  display: inline-block;
  background-color: var(--background-color);
  color: var(--color);
  border: 2px solid var(--border-color);

  &--light {
    --background-color: #{$color-white};
    --color: #{$color-secondary};
    --border-color: #{$color-white};
  }

  &--outlined {
    --background-color: transparent;
    --color: #{$color-secondary};
    --border-color: #{$color-secondary};
  }

  &--hover {
    --background-color: #{$color-primary};
    --color: #{$color-white};
    --border-color: #{$color-primary};

    &#{$self}--light {
      --background-color: #{$color-secondary};
      --color: #{$color-white};
      --border-color: #{$color-secondary};
    }

    &#{$self}--outlined {
      --background-color: transparent;
      --color: #{$color-primary};
      --border-color: #{$color-primary};
    }
  }

  a:hover &,
  &:hover {
    @extend #{$self}--hover;
  }
}