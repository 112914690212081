$base-class: '.el-CardFaq';

@mixin useCardFaqVariants($variant) {
  @include useVariants($base-class, $variant) {
    @content;
  }
}

#{$base-class} {
  --border-color: #{$color-neutral-light};
  --heading-color: #{$color-black};
  --heading-color-hover: #{$color-primary};
  --heading-color-active: #{$color-primary};
  --icon-color: #{$color-black};
  --icon-color-hover: #{$color-primary};
  --icon-color-active: #{$color-primary};
  @include interpolate((padding-top, padding-bottom), 15px, 30px);
  border-bottom: 2px solid var(--border-color);

  &__toggle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    &__heading {
      @include font-h4;
      @include transition(color);
      max-width: 18em;
      flex: 1;
      color: var(--heading-color);
    }

    &__icon {
      @include transition(color);
      flex: none;

      svg {
        fill: var(--icon-color);

        * {
          fill: inherit;
        }
      }
    }

    &:hover & {
      &__heading {
        color: var(--heading-color-hover);
      }

      &__icon {
        svg {
          fill: var(--icon-color-hover);
        }
      }
    }

    @include useCardFaqVariants(active) {
      &__heading {
        color: var(--heading-color-active);
      }

      &__icon {
        svg {
          fill: var(--icon-color-active);
        }
      }
    }
  }

  &__copy {
    overflow: hidden;
    height: 0;

    &__inner {
      @include interpolate(padding-top, 15px, 30px);
      @include interpolate(padding-bottom, 10px, 20px);
      @include interpolate((padding-left, padding-right), 60px, 120px);
      @include interpolate(margin-top, -15px, -30px);
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-wrap: wrap;

      @include breakpoint("medium", "max") {
        padding-left: 0;
        padding-right: 0;
      }
    }

    &__block {
      @include interpolate(margin-top, 15px, 30px);
      @include richText;

      &:not(:first-child:last-child) {
        @include breakpoint(medium) {
          flex: 0 1 47%;
        }
      }
    }
  }
}