$base-class: '.mol-MediaTrio';

@mixin useMediaTrioVariants($variant) {
  @include useVariants($base-class, $variant) {
    @content;
  }
}

#{$base-class} {
  @include sectionSpacing;
  @include useDisableSpacing;

  &__items {
    @include interpolate((margin-left, margin-right), -2px, -4px);
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    flex-wrap: wrap;

    &__single,
    &__duo {
      flex: 1 1 100%;
    }

    &__single {
      @include breakpoint(small) {
        flex: 0 1 55%;
      }
    }

    &__duo {
      @include breakpoint(small) {
        flex: 0 1 45%;
      }
    }
  }

  &__items__single &__item {
    &__inner {
      @include aspectRatio(1, 1);
    }
  }

  &__items__duo {
    display: flex;
    flex-direction: column;
    align-items: stretch
  }

  &__items__duo &__item {
    flex: 0 1 50%;

    &__inner {
      @include aspectRatio(16, 9);

      @include breakpoint(small) {
        height: 100%;
        padding-top: 0;
      }
    }
  }

  &__item {
    @include interpolate(padding, 2px, 4px);

    &__inner {
      position: relative;
    }
  }

  &__image {
    @include backgroundImage;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}