$base-class: '.mol-Loader';

@mixin useLoaderVariants($variant) {
  @include useVariants($base-class, $variant) {
    @content;
  }
}

#{$base-class} {
  --background-color: #{$color-primary-dark};
  --icon-color: #{$color-neutral-light};
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: $zIndex-loader;
  background-color: var(--background-color);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;

  &:before,
  &:after {
    content: "";
    position: absolute;
    width: 35%;
    height: 100%;
    z-index: $zIndex-loader;
    background-image: url(/built/images/shape-vertical.svg);
    background-repeat: no-repeat;
    background-size: contain;
    opacity: .08;

    @include breakpoint(small, "max") {
      width: 40%;
    }
  }

  &:before {
    left: 0;
    background-position: top left;
  }

  &:after {
    right: 0;
    background-position: top left;
    transform: scaleX(-1) scaleY(-1);
  }

  &__icon {
    @include interpolate(width, 65px, 130px);
    @include interpolate(height, 80px, 160px);
    opacity: 0;
    z-index: $zIndex-loader + 1;

    svg {
      fill: var(--icon-color);
      width: 100%;
      height: 100%;

      * {
        fill: inherit;
      }
    }
  }
}