$base-class: '.mol-Header';

@mixin useHeaderVariants($variant) {
  @include useVariants($base-class, $variant) {
    @content;
  }
}

@mixin useHeaderSpacing($top: true, $bottom: false) {
  @if $top==true {
    @include interpolate(padding-top, 65px, 130);
  }

  @if $bottom==true {
    @include interpolate(padding-bottom, 65px, 130);
  }
}

#{$base-class} {
  $breakpoint-hamburger: $bp-l;
  --background-color: transparent;
  position: relative;

  &--light {
    --background-color: transparent;
  }

  .grid-container {
    @include interpolate((padding-left, padding-right), 20px, 60px);
    max-width: 100%;
    overflow: visible;
  }

  &__wrapper {
    background-color: var(--background-color);

    @include useHeaderVariants(floating) {
      background-color: transparent;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
    }
  }

  &__inner {
    @include interpolate(height, 65px, 130);
    @include interpolate((padding-top, padding-bottom), 15px, 20px);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__logo,
  &__button,
  &__navigation-bar {
    z-index: $zIndex-header;
  }

  &__navigation-bar {
    @include interpolate((margin-left, margin-right), 18px, 36px);
    display: block;
    flex: 1;

    @include breakpoint($breakpoint-hamburger, "max") {
      display: none;
    }
  }

  &__logo {
    line-height: 0;
    position: relative;

    &__link {
      display: inline-block;
      max-width: 100%;
    }

    &__image {
      @include interpolate(height, 20px, 50px);
      @include interpolate(width, 140px, 350px);
      background-size: contain;
      background-position: left center;
      background-repeat: no-repeat;
    }
  }

  &__buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__button {
    @include breakpoint("xsmall", "max") {
      display: none;
    }
  }

  &__hamburger {
    @include interpolate(margin-left, 18px, 36px);
    z-index: $zIndex-header + 2;
    // display: none;

    // @include breakpoint($breakpoint-hamburger, "max") {
    //   display: block;
    // }
  }
}