$base-class: '.el-PopupUnit';

@mixin usePopupUnitVariants($variant) {
  @include useVariants($base-class, $variant) {
    @content;
  }
}

#{$base-class} {
  --label-icon-color: #{$color-secondary};
  @include interpolate(padding, 15px, 30px);
  display: flex;
  align-items: flex-start;

  @include breakpoint("small", "max") {
    display: block;
  }

  &__image {
    overflow: hidden;
    flex: 0 1 40%;

    &__inner {
      @include backgroundImage;
      @include aspectRatio(4, 3);
    }
  }

  &__content {
    @include interpolate(margin-left, 15px, 30px);
    @include interpolate(margin-top, 15px, 30px);
    @include interpolate(padding-bottom, 10px, 20px);
    flex: 1;

    @include breakpoint("small", "max") {
      margin-left: 5%;
      margin-right: 5%;
    }

    @include breakpoint("small") {
      margin-top: 0;
      padding-bottom: 0;
    }
  }

  &__heading {
    @include font-h4;
  }

  &__subheading {
    @include font-body-small;
    @include interpolate(margin-top, 4px, 8px);
    display: block;
  }

  &__labels {
    @include interpolate(margin-top, 4px, 8px);
    @include interpolate((margin-left, margin-right), -5px, -10px);
  }

  &__label {
    @include font-body-xsmall;
    @include flexbox(center, flex-start, $display: inline-flex);
    @include interpolate((padding-left, padding-right), 5px, 10px);

    &+& {
      @include interpolate(margin-top, 3px, 6px);
    }

    &__icon {
      @include interpolate(margin-right, 5px, 10px);
      @include interpolate((width, height), 12px, 24px);
      display: block;

      svg {
        fill: var(--label-icon-color);
        height: 100%;
        width: 100%;

        * {
          fill: inherit;
        }
      }
    }

    &__text {
      display: block;
    }
  }

  &__subcopy {
    @include interpolate(margin-top, 4px, 8px);
    @include font-body-xsmall;
    display: block;
  }

  &__button {
    @include interpolate(margin-top, 10px, 20px);
    display: block;
  }
}