$base-class: '.mol-HeroComplex';

@mixin useHeroComplexVariants($variant) {
  @include useVariants($base-class, $variant) {
    @content;
  }
}

#{$base-class} {
  --background-color: #{$color-primary-dark};
  --color: #{$color-white};
  background-color: var(--background-color);
  color: var(--color);

  &__content {
    @include sectionSpacing;
    text-align: center;
  }

  &__heading {
    @include font-h1;
    @include maxWidth(15em);
  }

  &__copy {
    @include interpolate(margin-top, 15px, 30px);
    @include font-body;
    @include maxWidth(40em);
  }

  &__button {
    @include interpolate(margin-top, 10px, 20px);
  }

  &__carousel {
    @include useCarousel;
    position: relative;

    &__spacer {
      @include aspectRatio(16, 8);
    }
  }

}