$base-class: '.mol-QuoteStandard';

@mixin useQuoteStandardVariants($variant) {
  @include useVariants($base-class, $variant) {
    @content;
  }
}

#{$base-class} {
  --quote-color: #{$color-black};
  --icon-color: #{$color-primary};
  --author-color: #{$color-black};
  --dots-color: #{rgba($color-black, .2)};
  --dots-color-active: #{$color-black};

  @include sectionSpacing(large);
  @include useDisableSpacing;

  &__items {
    .slick-track {
      display: flex;

      .slick-slide {
        display: flex;
        height: auto;
        align-items: center;
        justify-content: center;
      }
    }
  }

  &__item {
    text-align: center;

    &__icon {
      @include interpolate(margin-bottom, 10px, 20px);
      @include interpolate((width, height), 30px, 60px);
      margin-left: auto;
      margin-right: auto;

      svg {
        width: 100%;
        height: 100%;
        fill: var(--icon-color);

        * {
          fill: inherit;
        }
      }
    }

    &__quote {
      @include font-h3;
      @include maxWidth(20em);
      color: var(--quote-color);
    }

    &__author {
      @include interpolate(margin-top, 15px, 30px);
      @include font-body;
      color: var(--author-color);
    }
  }

  &__dots {
    @include interpolate(margin-top, 25px, 50px);

    .slick-dots {
      display: flex;
      align-items: center;
      justify-content: center;

      li {
        display: inline-block;
        @include interpolate(padding, 3px, 6px);
        line-height: 0;
        cursor: pointer;
        opacity: 1;
        @include transition(opacity);

        &.slick-active button {
          background-color: var(--dots-color-active);
        }

        button {
          @include interpolate((width, height), 4px, 8px);
          text-indent: -9999px;
          background-color: var(--dots-color);
          border-radius: 50%;
          cursor: pointer;
          @include transition(background-color);
        }

        &:hover {
          opacity: .6;
        }
      }
    }

  }
}