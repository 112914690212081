$base-class: '.mol-CallToActionStandard';

@mixin useCallToActionStandardVariants($variant) {
  @include useVariants($base-class, $variant) {
    @content;
  }
}

#{$base-class} {
  --color: #{$color-white};
  --overlay-color: #{rgba($color-neutral-darkest, .5)};
  @include sectionSpacing;
  @include useDisableSpacing;

  &__inner {
    position: relative;
    display: flex;
    align-items: center;

    &:before {
      content: "";
      padding-top: 56.25%;
    }
  }

  &__media {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    &__inner {
      position: relative;
      height: 100%;
      width: 100%;

      &:after {
        content: "";
        background-color: var(--overlay-color);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 0;
      }
    }
  }

  &__content {
    @include sectionSpacing(large);
    position: relative;
    color: var(--color);
    text-align: center;
    z-index: 2;
  }

  &__label {
    @include font-h6;
    @include interpolate(margin-bottom, 10px, 20px);
  }

  &__heading {
    @include font-h2;
    @include maxWidth(15em);
  }

  &__copy {
    @include interpolate(margin-top, 25px, 50px);
    @include font-body;
    @include maxWidth(40em);
  }

  &__button {
    @include interpolate(margin-top, 25px, 50px);
  }
}