$base-class: '.el-Pagination';

@mixin usePaginationVariants($variant) {
  @include useVariants($base-class, $variant) {
    @content;
  }
}

#{$base-class} {
  --color: #{$color-black};
  --color-hover: #{$color-primary};
  --color-active: #{$color-secondary};
  @include sectionSpacing(xsmall);
  width: 100%;

  &__list {
    @include flexbox(center, center);
  }

  &__button__link,
  &__number__link {
    @include transition(color, 0.25s);

    &:hover {
      color: var(--color-hover);
    }
  }

  &__ellipsis {
    @include interpolate((margin-left, margin-right), 5px, 10px);
    cursor: default;
  }

  &__button {
    @include interpolate((margin-left, margin-right), 10px, 30px);
    line-height: 0;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  &__number {
    @include interpolate((margin-left, margin-right), 5px, 10px);
    @include font-body-small;
    font-weight: $font-weight-bold;
    $number: &;

    &--current {
      cursor: default;

      #{$number}__link,
      #{$number}__text {
        color: var(--color-active);
      }
    }

    &__text,
    &__link {
      @include interpolate(padding, 3px, 6px);
      color: var(--color);
    }
  }
}