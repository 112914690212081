$base-class: '.mol-ContentTextTypeTwo';

@mixin useContentTextTypeTwoVariants($variant) {
  @include useVariants($base-class, $variant) {
    @content;
  }
}

#{$base-class} {
  --icon-color: #{$color-secondary};
  --background-color: #{$color-neutral-light};
  @include sectionSpacing;
  @include useDisableSpacing;
  background-color: var(--background-color);

  &__label {
    @include font-h6;
    @include interpolate(margin-bottom, 10px, 20px);
  }

  &__icon {
    &:before {
      content: "";
      @include interpolate(width, 1px, 3px);
      @include interpolate(height, 30px, 60px);
      display: block;
      background-color: var(--icon-color);
    }
  }

  &__heading {
    @include interpolate(margin-bottom, 20px, 40px);
    @include font-h2;
    max-width: 15em;
  }

  &__copy {
    @include interpolate(margin-top, -15px, -30px);
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;

    &__block {
      @include interpolate(margin-top, 15px, 30px);
      @include richText;

      &:not(:first-child:last-child) {
        @include breakpoint(medium) {
          flex: 0 1 47%;
        }
      }
    }
  }

  &__button {
    @include interpolate(margin-top, 25px, 50px);
  }
}