$base-class: '.mol-ModalHeader';

@mixin useModalHeaderVariants($variant) {
  @include useVariants($base-class, $variant) {
    @content;
  }
}

#{$base-class} {
  @include useHeaderSpacing($bottom: true);
  --background-color: #{$color-primary};
  --color: #{$color-white};
  --navigation-primary-color: #{$color-white};
  --navigation-primary-border-color: transparent;
  --navigation-secondary-color: #{$color-white};
  --navigation-secondary-border-color: transparent;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--background-color);
  z-index: $zIndex-header+1;
  display: flex;
  color: var(--color);
  visibility: hidden;
  opacity: 0;
  overflow: scroll;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url(/built/images/pattern.svg);
    opacity: .12;
    @include interpolate(background-size, 700px, 1400px);
    background-repeat: repeat;
  }

  &__inner {
    margin-top: auto;
    margin-bottom: auto;
    width: 100%;
  }

  &__navigation {
    align-items: center;
  }

  &__navigation-primary {
    @include interpolate((padding-top, padding-bottom), 5px, 10px);

    a {
      position: relative;
      color: var(--navigation-primary-color);

      &:before {
        @include transition(background-color);
        content: "";
        left: 0;
        right: 0;
        bottom: .1em;
        height: .07em;
        position: absolute;
        background-color: var(--navigation-primary-border-color);
      }

      &:hover {
        --navigation-primary-border-color: #{rgba($color-white, .25)};
      }
    }

    &__items {
      @include interpolate((margin-top, margin-bottom), -2px, -4px);
    }

    &__item {
      @include interpolate((margin-top, margin-bottom), 2px, 4px);
      @include font-h1;

      &__subitems {
        @include interpolate((margin-top, margin-bottom), -2px, -4px);
        @include interpolate((padding-bottom, padding-top), 6px, 12px);
        @include interpolate(padding-left, 20px, 40px);

        @include breakpoint("small", "max") {
          padding-left: 0;
        }
      }

      &__subitem {
        @include interpolate((margin-top, margin-bottom), 5px, 10px);
        @include font-h4;
      }
    }

    @include breakpoint("small", "max") {
      text-align: center;
    }
  }

  &__navigation-secondary {
    @include interpolate(margin-top, 25px, 50px);

    a {
      position: relative;
      color: var(--navigation-secondary-color);

      &:before {
        @include transition(background-color);
        content: "";
        left: 0;
        right: 0;
        bottom: .1em;
        height: .07em;
        position: absolute;
        background-color: var(--navigation-secondary-border-color);
      }

      &:hover {
        --navigation-secondary-border-color: #{rgba($color-white, .25)};
      }
    }

    &__items {
      @include interpolate((margin-top, margin-bottom), -2px, -4px);
    }

    &__item {
      @include interpolate((margin-top, margin-bottom), 2px, 4px);
      @include font-h2;
    }

    @include breakpoint(medium) {
      margin-top: 0;
    }

    @include breakpoint("small", "max") {
      text-align: center;
    }
  }
}