$base-class: '.atom-ImageHidden';

@mixin useImageHiddenVariants($variant) {
  @include useVariants($base-class, $variant) {
    @content;
  }
}

#{$base-class} {
  @include hideElement;
}