$base-class: '.el-HeaderNavigation';

@mixin useHeaderNavigationVariants($variant) {
  @include useVariants($base-class, $variant) {
    @content;
  }
}

#{$base-class} {
  --color: #{$color-black};
  --color-hover: #{$color-secondary};
  --subitem-color: #{$color-black};
  --subitem-color-hover: #{$color-secondary};

  &--light {
    --color: #{$color-white};
    --color-hover: #{$color-secondary};
  }

  &__items {
    @include interpolate((margin-left, margin-right), -7px, -14px);
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__item {
    @include interpolate((margin-left, margin-right), 7px, 14px);
    color: var(--color);

    &__link {
      @include transition(color);
      @include font-body-small;

      &:hover {
        color: var(--color-hover);
      }
    }

    &__subitems {
      text-align: center;
      display: none;
    }

    &__subitem {
      @include font-body-small;
      color: var(--subitem-color);

      &+& {
        @include interpolate(margin-top, 2px, 4px);
      }

      &__link {
        @include transition(color);
        @include font-body-small;

        &:hover {
          color: var(--subitem-color-hover);
        }
      }
    }
  }

  .tippy-box[data-theme~='headerNavigation-subitems'] {
    background-color: $color-white;
    color: $color-black;
    padding: 0;
    filter: drop-shadow(0 0px 20px rgba($color-neutral, 0.3));
    @include interpolate(border-radius, 2px, 4px);
    background-color: $color-white;
    max-width: 80%;

    .tippy-content {
      // width: 45em;
      max-width: 70vw;
      padding: 0;
      @include interpolate((padding-left, padding-right), 15px, 30px);
      @include interpolate((padding-top, padding-bottom), 10px, 20px);

      @include breakpoint("small", "max") {
        width: auto;
        width: 20em;
        max-width: 50vw;
      }
    }
  }

  .tippy-box[data-theme~='headerNavigation-subitems']>.tippy-arrow::before {
    // transform: scale(2);
  }

  .tippy-box[data-theme~='headerNavigation-subitems'][data-placement^='top']>.tippy-arrow::before {
    border-top-color: $color-white;
  }

  .tippy-box[data-theme~='headerNavigation-subitems'][data-placement^='bottom']>.tippy-arrow::before {
    border-bottom-color: $color-white;
  }

  .tippy-box[data-theme~='headerNavigation-subitems'][data-placement^='left']>.tippy-arrow::before {
    border-left-color: $color-white;
  }

  .tippy-box[data-theme~='headerNavigation-subitems'][data-placement^='right']>.tippy-arrow::before {
    border-right-color: $color-white;
  }
}