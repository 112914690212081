$base-class: '.mol-MediaSingle';

@mixin useMediaSingleVariants($variant) {
  @include useVariants($base-class, $variant) {
    @content;
  }
}

#{$base-class} {
  @include sectionSpacing;
  @include useDisableSpacing;

  &__introduction {
    @include interpolate(margin-bottom, 40px, 80px);
  }

  &__label {
    @include font-h6;
    @include interpolate(margin-bottom, 15px, 30px);
  }

  &__heading {
    @include font-h2;
    max-width: 15em;
  }

  &__copy {
    @include font-body;
    @include interpolate(margin-top, 20px, 40px);
    max-width: 40em;
  }

  &__button-wrapper {
    align-self: flex-end;

    @include breakpoint(large) {
      display: flex;
      justify-content: flex-end;
    }
  }

  &__button {
    @include interpolate(margin-top, 20px, 40px);
  }

  &__video {
    position: relative;
    @include aspectRatio(16, 9);
  }
}