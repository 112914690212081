$base-class: '.mol-ModalForm';

@mixin useModalFormVariants($variant) {
  @include useVariants($base-class, $variant) {
    @content;
  }
}

#{$base-class} {
  --background-color: #{$color-primary};
  --color: #{$color-white};
  --close-background-color: #{$color-white};
  --close-color: #{$color-primary};
  --close-background-color-hover: #{$color-secondary};
  --close-color-hover: #{$color-white};
  --overlay-color: #{rgba($color-white, .3)};
  visibility: hidden;
  opacity: 0;
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: $zIndex-modal-form;

  &__wrapper {
    @include sectionSpacing;
    background-color: var(--background-color);
    color: var(--color);
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    z-index: $zIndex-modal-form;
    overflow: scroll;
    display: flex;

    @include breakpoint(medium) {
      width: 75%;
    }

    @include breakpoint(large) {
      width: 50%;
    }

  }

  &__inner {
    width: 100%;
    margin-top: auto;
    margin-bottom: auto;
  }

  &__label {
    @include font-h6;
    @include interpolate(margin-bottom, 15px, 30px);
  }

  &__heading {
    @include font-h2;
    max-width: 15em
  }

  &__form {
    @include interpolate(margin-top, 30px, 60px);
  }

  &__close {
    @include interpolate((width, height), 20px, 40px);
    @include interpolate((top, right), 10px, 20px);
    @include transition((background-color, color));
    position: fixed;
    background-color: var(--close-background-color);
    color: var(--close-color);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    &__text {
      @include font-h5;
      transform: translateY(-3%);
      display: block;
    }

    &:hover {
      background-color: var(--close-background-color-hover);
      color: var(--close-color-hover);
    }
  }

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: $zIndex-modal-form - 1;
    background-color: var(--overlay-color);
  }
}