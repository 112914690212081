$base-class: '.atom-ButtonSecondary';

@mixin useButtonSecondaryVariants($variant) {
  @include useVariants($base-class, $variant) {
    @content;
  }
}

#{$base-class} {
  $self: &;
  --color: #{$color-secondary};
  --border-color: #{$color-secondary};
  @include font-body-small;
  @include transition((color, border-bottom-color));
  display: inline-block;
  border: 0px solid var(--border-color);
  border-bottom-width: 1px;
  color: var(--color);
  font-weight: $font-weight-bold;

  &--light {
    --color: #{$color-white};
    --border-color: #{$color-white};
  }

  &--hover {
    --color: #{$color-primary};
    --border-color: #{$color-primary};

    &#{$self}--light {
      --color: #{$color-secondary};
      --border-color: #{$color-secondary};
    }
  }

  a:hover &,
  &:hover {
    @extend #{$self}--hover;
  }
}