@mixin breakpoint($breakpoint, $minMax: "min") {
  @if $breakpoint=="xsmall only" {
    @media all and (max-width: #{$bp-xs - 1}) {
      @content;
    }
  }

  @else if $breakpoint=="small only" {
    @media all and (min-width: #{$bp-xs}) and (max-width: $bp-s - 1) {
      @content;
    }
  }

  @else if $breakpoint=="medium only" {
    @media all and (min-width: #{$bp-s}) and (max-width: $bp-m - 1) {
      @content;
    }
  }

  @else if $breakpoint=="large only" {
    @media all and (min-width: #{$bp-m}) and (max-width: $bp-l - 1) {
      @content;
    }
  }

  @else if $breakpoint=="xlarge only" {
    @media all and (min-width: #{$bp-l}) and (max-width: $bp-xl - 1) {
      @content;
    }
  }

  @else if $breakpoint=="xsmall" {
    @if $minMax=="max" {
      @media all and (max-width: $bp-xs) {
        @content;
      }
    }

    @else {
      @media all and (min-width: $bp-xxs + 1) {
        @content;
      }
    }
  }

  @else if $breakpoint=="small" {
    @if $minMax=="max" {
      @media all and (max-width: $bp-s) {
        @content;
      }
    }

    @else {
      @media all and (min-width: $bp-xs + 1) {
        @content;
      }
    }
  }

  @else if $breakpoint=="medium" {
    @if $minMax=="max" {
      @media all and (max-width: $bp-m) {
        @content;
      }
    }

    @else {
      @media all and (min-width: $bp-s + 1) {
        @content;
      }
    }
  }

  @else if $breakpoint=="large" {
    @if $minMax=="max" {
      @media all and (max-width: $bp-l) {
        @content;
      }
    }

    @else {
      @media all and (min-width: $bp-m + 1) {
        @content;
      }
    }
  }

  @else if $breakpoint=="xlarge" {
    @if $minMax=="max" {
      @media all and (max-width: $bp-xl) {
        @content;
      }
    }

    @else {
      @media all and (min-width: $bp-l + 1) {
        @content;
      }
    }
  }

  @else {
    @if $minMax=="max" {
      @media all and (max-width: $breakpoint) {
        @content;
      }
    }

    @else {
      @media all and (min-width: $breakpoint) {
        @content;
      }
    }

  }
}

//
// Loading our fonts
//
@mixin font-face($font-family, $font-filename, $font-weight: 400, $font-style :normal) {
  @font-face {
    font-family: "#{$font-family}";
    font-style: $font-style;
    font-weight: $font-weight;
    src: url("/built/fonts/#{$font-filename}.eot");
    src: url("/built/fonts/#{$font-filename}.eot?#iefix") format("embedded-opentype"),
    url("/built/fonts/#{$font-filename}.woff") format("woff"),
    url("/built/fonts/#{$font-filename}.ttf") format("truetype"),
    url("/built/fonts/#{$font-filename}.svg##{$font-family}") format("svg");
  }
}

//
// Font mixin
//

@mixin font($font-name: "Helvetica", $font-weight: false, $font-size-min: false, $font-size-max: false, $line-height: false, $font-style: false, $letter-spacing: false) {

  font-family: $font-name;

  @if $font-weight !=false {
    font-weight: $font-weight;
  }

  @else {
    font-weight: 300;
  }

  @include interpolate(font-size, $font-size-min, $font-size-max);

  @if $line-height !=false {
    line-height: $line-height;
  }

  @else {
    line-height: 1.3;
  }

  @if $font-style !=false {
    font-style: $font-style;
  }

  @if $letter-spacing !=false {
    letter-spacing: $letter-spacing;
  }

}

/* Variants
--------------------------------------------------------------*/
@mixin useVariants($baseClass, $variant, $variant2: null) {
  @if $variant2 !=null {
    #{$baseClass}--#{$variant}#{$baseClass}--#{$variant2} & {
      @content;
    }
  }

  @else {
    #{$baseClass}--#{$variant} & {
      @content;
    }
  }
}

@mixin triggerVariant($element, $variant) {
  #{$element} {
    @extend #{$element}--#{$variant} !optional;
  }
}

//
// A method used for determing fluid responsive properties
//
@mixin interpolate($properties,
  $min-value,
  $max-value,
  $min-screen: $bp-xxs,
  $max-screen: $bp-xl) {
  & {
    @each $property in $properties {
      #{$property}: $min-value;
    }

    @media screen and (min-width: $min-screen) {
      @each $property in $properties {
        #{$property}: calc-interpolation($min-value,
            $max-value,
            $min-screen,
            $max-screen);
      }
    }

    @media screen and (min-width: $max-screen) {
      @each $property in $properties {
        #{$property}: $max-value;
      }
    }
  }
}

@mixin vertical-align($position: relative) {
  position: $position;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

//
// Requires the calc-interpolation function which can also be used independently
//
@function calc-interpolation($min-value,
  $max-value,
  $min-screen: $bp-xs,
  $max-screen: $bp-xl) {
  $a: ($max-value - $min-value) / ($max-screen - $min-screen);
  $b: $min-value - $a * $min-screen;

  $sign: '+';

  @if ($b < 0) {
    $sign: '-';
    $b: abs($b);
  }

  @return calc(#{$a * 100}vw #{$sign} #{$b});
}

@mixin input-placeholder {

  // used in _base.scss
  // replaces compass/css/user-interface/input-placeholder()
  &::-webkit-input-placeholder {
    @content;
  }

  &:-moz-placeholder {
    @content;
    opacity: 1;
  }

  &::-moz-placeholder {
    @content;
    opacity: 1;
  }

  &:-ms-input-placeholder {
    @content;
  }
}

// opentype stuff
.ligatures {
  -webkit-font-feature-settings: 'liga', 'dlig';
  -moz-font-feature-settings: 'liga=1, dlig=1';
  -ms-font-feature-settings: 'liga', 'dlig';
  -o-font-feature-settings: 'liga', 'dlig';
  font-feature-settings: 'liga', 'dlig';
}

.fractions {
  -webkit-font-feature-settings: 'frac'1;
  -moz-font-feature-settings: 'frac=1';
  -moz-font-feature-settings: 'frac'1;
  -ms-font-feature-settings: 'frac'1;
  -o-font-feature-settings: 'frac'1;
  font-feature-settings: 'frac'1;
}

.little-numbers {
  -webkit-font-feature-settings: 'onum'1;
  -moz-font-feature-settings: 'onum=1';
  -moz-font-feature-settings: 'onum'1;
  -ms-font-feature-settings: 'onum'1;
  -o-font-feature-settings: 'onum'1;
  font-feature-settings: 'onum'1;
}

.big-numbers {
  -webkit-font-feature-settings: 'lnum'1;
  -moz-font-feature-settings: 'lnum=1';
  -moz-font-feature-settings: 'lnum'1;
  -ms-font-feature-settings: 'lnum'1;
  -o-font-feature-settings: 'lnum'1;
  font-feature-settings: 'lnum'1;
}

.small-caps {
  -webkit-font-feature-settings: 'smcp'1;
  -moz-font-feature-settings: 'smcp=1';
  -moz-font-feature-settings: 'smcp'1;
  -ms-font-feature-settings: 'smcp'1;
  -o-font-feature-settings: 'smcp'1;
  font-feature-settings: 'smcp'1;
}

.sub-script {
  -webkit-font-feature-settings: 'subs'1;
  -moz-font-feature-settings: 'subs=1';
  -moz-font-feature-settings: 'subs'1;
  -ms-font-feature-settings: 'subs'1;
  -o-font-feature-settings: 'subs'1;
  font-feature-settings: 'subs'1;
}

.super-script {
  -webkit-font-feature-settings: 'sups'1;
  -moz-font-feature-settings: 'sups=1';
  -moz-font-feature-settings: 'sups'1;
  -ms-font-feature-settings: 'sups'1;
  -o-font-feature-settings: 'sups'1;
  font-feature-settings: 'sups'1;
}

/* Flexbox
--------------------------------------------------------------*/
@mixin flexbox($align: center,
  $justify: space-between,
  $direction: row,
  $wrap: wrap,
  $display: flex) {
  display: $display;
  flex-direction: $direction;
  align-items: $align;
  flex-wrap: $wrap;
  justify-content: $justify;
}

/* Aspect Ratio
--------------------------------------------------------------*/
@mixin aspectRatio($w: 16, $h: 9) {
  width: 100%;
  height: 0;
  padding-top: calc(#{$h}/#{$w}* 100%);
}

/* Background Image
--------------------------------------------------------------*/
@mixin backgroundImage($size: cover,
  $position: center center,
  $repeat: no-repeat) {
  background-size: $size;
  background-repeat: $repeat;
  background-position: $position;
}

/* Transition
--------------------------------------------------------------*/
@mixin transition($target: all,
  $time: 0.2s,
  $type: ease-in-out,
  $delay: 0,
  $important: false) {
  @if $important==true {
    transition: all $time $type !important;
    transition-property: $target !important;
    transition-delay: $delay;
  }

  @else {
    transition: all $time $type;
    transition-property: $target;
    transition-delay: $delay;
  }
}

/* Max-width
--------------------------------------------------------------*/
@mixin maxWidth($width, $centered: true) {
  max-width: $width;

  @if $centered==true {
    margin-left: auto;
    margin-right: auto;
  }
}

/* Fill parent
--------------------------------------------------------------*/
@mixin absoluteFill {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Centered relative to parent
--------------------------------------------------------------*/
@mixin absoluteCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin triggerHoverClass($element) {
  #{$element} {
    @extend #{$element}--hover !optional;
  }
}

@mixin triggerActiveClass($element) {
  #{$element} {
    @extend #{$element}--active !optional;
  }
}

@mixin sectionSpacingTop($size: medium) {
  @if $size==xsmall {
    @include interpolate(padding-top, 15px, 30px);
  }

  @if $size==small {
    @include interpolate(padding-top, 30px, 60px);
  }

  @if $size==medium {
    @include interpolate(padding-top, 50px, 100px);
  }

  @if $size==large {
    @include interpolate(padding-top, 70px, 140px);
  }
}

@mixin sectionSpacingBottom($size: medium) {
  @if $size==xsmall {
    @include interpolate(padding-bottom, 15px, 30px);
  }

  @if $size==small {
    @include interpolate(padding-bottom, 30px, 60px);
  }

  @if $size==medium {
    @include interpolate(padding-bottom, 50px, 100px);
  }

  @if $size==large {
    @include interpolate(padding-bottom, 70px, 140px);
  }
}

@mixin sectionSpacing($size: medium) {
  @include sectionSpacingTop($size);
  @include sectionSpacingBottom($size);
}

@mixin hideElement {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

// Hides target div leveraging slick-slider until the carousel is initialized
@mixin slick() {
  display: none;
  visibility: hidden;

  &.slick-initialized {
    display: block;
    visibility: visible;
  }
}

@mixin formReset {
  div[id^='__lpform_'] {
    display: none;
  }

  input,
  textarea,
  select {
    width: 100%;
    outline: none;
    border: none;
    -webkit-appearance: none;
    resize: none;
    background: none;
    font-size: inherit;
    font-family: inherit;
    font-weight: inherit;
    color: inherit;
    line-height: normal;
    padding: 0;
    border: none;
    box-shadow: none !important;

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      transition: background-color 99999s ease-in-out 0s;
      -webkit-text-fill-color: $color-black;
      font-size: inherit;
      font-family: inherit;
      font-weight: inherit;
      color: inherit;
    }

    &::-ms-clear {
      display: none;
    }

    &::placeholder {
      color: inherit;
      opacity: 0.5;
    }
  }

  input[type='search']::-webkit-search-decoration,
  input[type='search']::-webkit-search-cancel-button,
  input[type='search']::-webkit-search-results-button,
  input[type='search']::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }

  .fui-field,
  .fui-row,
  .fui-btn-container {
    margin: 0;
    padding: 0;
  }
}

@mixin sliderDots() {
  $dots: &;
  @include flexbox(center, center);

  li {
    cursor: pointer;

    &:not(:last-child) {
      @include interpolate(margin-right, 8px, 16px);
    }

    button {
      @include interpolate((width, height), 12px, 18px);
      @include transition((background-color, transform), 0.25s);
      display: block;
      background-color: $color-white;
      border-radius: 50%;
      text-indent: -9999px;
      font-size: 0;
      border: 1px solid $color-white;
      transform: scale(0.6);
      padding: 0;
    }

    &.slick-active button {
      background-color: transparent;
      transform: scale(1);
    }
  }
}

@mixin useTrim($addSpace: true) {
  position: relative;

  .atom-TrimWave,
  .atom-TrimSlope {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 100%;
  }

  @if $addSpace==true {
    &:after {
      content: "";
      display: block;
      height: 0;
      padding-top: 5.35%;
    }
  }
}

@mixin hideTrimSpacer() {
  &:after {
    display: none;
  }
}

@mixin hideTrim() {

  .atom-TrimWave,
  .atom-TrimSlope {
    display: none;
  }
}

@mixin useDisableSpacing($top: true, $bottom: true) {
  @if $top==true {
    &--disableTopSpacing {
      padding-top: 0;
    }
  }

  @if $bottom==true {
    &--disableBottomSpacing {
      padding-bottom: 0;
    }
  }
}