$base-class: '.mol-ModalUnit';

@mixin useModalUnitVariants($variant) {
  @include useVariants($base-class, $variant) {
    @content;
  }
}

#{$base-class} {
  --overlay-color: #{rgba($color-primary, .7)};
  --loader-icon-color: #{$color-neutral};
  --loader-background-color: #{$color-white};
  --inner-background-color: #{$color-white};
  --aside-background-color: #{$color-neutral-light};
  --label-icon-color: #{$color-secondary};
  --close-background-color: #{$color-secondary};
  --close-color: #{$color-white};
  position: fixed;
  z-index: $zIndex-modal-unit;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  visibility: hidden;
  opacity: 0;
  overflow: hidden;
  height: 100%;
  width: 100%;

  [data-modal-unit-data-container="inactive"] {
    display: none;
  }

  &__wrapper {
    @include interpolate((padding-top, padding-bottom), 25px, 50px);
    @include interpolate((padding-left, padding-right), 40px, 80px);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    width: 100%;
    max-width: 100%;
    flex-direction: column;
    align-items: center;
  }

  &__overlay {
    background-color: var(--overlay-color);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: $zIndex-modal-unit + 1;
    cursor: pointer;
  }

  &__inner {
    background-color: var(--inner-background-color);
    position: relative;
    width: 100%;
    max-width: 150rem;
    margin-top: auto;
    margin-bottom: auto;
    z-index: $zIndex-modal-unit + 2;
    overflow: scroll;

    @include breakpoint("small", "max") {
      width: 80%;
    }

    @include breakpoint("xsmall", "max") {
      width: 100%;
    }
  }

  &__close {
    @include interpolate((width, height), 20px, 40px);
    @include interpolate((top, right), 10px, 20px);
    @include transition((background-color, color));
    position: fixed;
    background-color: var(--close-background-color);
    color: var(--close-color);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: $zIndex-modal-unit + 2;

    &__text {
      @include font-h3;
      transform: translateY(-3%);
      display: block;
    }

    &:hover {
      --close-background-color: #{$color-primary};
      --close-color: #{$color-white};
    }
  }

  &__loader {
    @include flexbox(center, center);
    position: relative;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--loader-background-color);
    z-index: 1003;

    &__icon {
      @include interpolate((width, height), 25px, 50px);
      transform-origin: center center;

      svg {
        width: 100%;
        height: 100%;
        fill: var(--loader-icon-color);

        * {
          fill: inherit;
        }
      }
    }
  }

  &__error {
    @include interpolate((padding-top, padding-bottom), 50px, 100px);
    @include interpolate((padding-left, padding-right), 30px, 60px);
    @include flexbox(center, center);
    text-align: center;
    height: 100%;
    opacity: 0;
    min-height: 70vh;

    &__heading {
      @include font-h4;
    }

    &__copy {
      @include interpolate(margin-top, 10px, 20px);

    }

    &__button {
      @include interpolate(margin-top, 20px, 40px);
    }
  }

  &__container {
    @include flexbox(stretch);
    position: relative;
    z-index: 1002;
    height: 0;
    overflow: hidden;
    min-height: 70vh;
    opacity: 0;

    @include breakpoint("small", "max") {
      display: block;
    }
  }

  &__aside {
    @include interpolate((padding-top, padding-left, padding-right), 20px, 40px);
    @include interpolate(padding-bottom, 25px, 50px);
    background-color: var(--aside-background-color);
    flex: 0 1 30%;

    @include breakpoint("medium", "max") {
      flex: 0 1 35%;
    }
  }

  &__labels {
    @include interpolate(margin-bottom, 15px, 30px);
    @include interpolate((margin-left, margin-right), -5px, -10px);
  }

  &__label {
    @include font-body-small;
    @include flexbox(center, center, $display: inline-flex);
    @include interpolate((padding-left, padding-right), 5px, 10px);

    &__icon {
      @include interpolate(margin-right, 5px, 10px);
      @include interpolate((width, height), 12px, 24px);
      display: block;

      svg {
        fill: var(--label-icon-color);
        height: 100%;
        width: 100%;

        * {
          fill: inherit;
        }
      }
    }

    &__text {
      display: block;
    }
  }

  &__heading {
    @include font-h4;
  }

  &__subheading {
    @include interpolate(margin-top, 6px, 12px);
    @include font-body;
  }

  &__details {
    @include interpolate(margin-top, 5px, 10px);
    @include flexbox(center, flex-start);

    &__item {
      @include font-body-xsmall;
      line-height: 1.3;

      &+& {
        &:before {
          content: '\02022';
          margin-left: .5em;
          margin-right: .5em;
        }
      }
    }
  }

  &__form-wrapper {
    @include interpolate(margin-top, 15px, 25px);
  }

  &__form-copy {
    @include font-body-small;
    @include interpolate(margin-bottom, 15px, 25px);
  }

  &__main {
    @include interpolate((padding-left, padding-right), 40px, 80px);
    @include interpolate((padding-top, padding-bottom), 25px, 50px);
    flex: 1;
  }

  &__carousel {
    @include interpolate((margin-top, margin-bottom), 30px, 60px);

    &:first-child {
      margin-top: 0
    }

    &:last-child {
      margin-top: 0
    }

    &__inner {
      @include aspectRatio(16, 9);
      @include useCarousel(true);
      position: relative;
      background-color: $color-neutral;
    }

    &:after {
      content: "";
      display: block;
      height: 1px;
    }
  }

  &__info {
    @include interpolate((margin-top, margin-bottom), 25px, 50px);

    &+&,
    &:first-child {
      margin-top: 0
    }

    &:last-child {
      margin-top: 0
    }

    &__item {
      &+& {
        @include interpolate(margin-top, 20px, 40px);
      }

      &__heading {
        @include font-h5;
        @include interpolate(margin-bottom, 10px, 20px);
      }

      &__intro {
        @include interpolate(margin-top, 10px, 20px);
        @include font-body-large;
      }

      &__description {
        @include interpolate(margin-top, 10px, 20px);
        @include richText;
        @include font-body-small;

        p,
        li {
          @include font-body-small;
        }

        p {
          margin-bottom: 1em;
          margin-top: .5em;
        }

        li {
          margin-bottom: 0 !important;
        }

        ol,
        ul {
          margin-top: .75em;
          margin-bottom: 1em;
        }
      }

      &__subheading {
        @include font-body-small;
        @include interpolate(margin-top, 0px, 5px);
      }

      &__list {
        @include interpolate(margin-top, 10px, 20px);
        columns: 2;
        column-gap: 5%;

        @include breakpoint("small", "max") {
          columns: 1;
        }

        li {
          page-break-inside: avoid;
          break-inside: avoid-column;
          display: block;
          @include font-body-small;
        }
      }
    }
  }

  &__floorplan-image {
    @include interpolate((margin-top, margin-bottom), 20px, 40px);

    &+&,
    &:first-child {
      margin-top: 0
    }

    &:last-child {
      margin-top: 0
    }

    img {
      max-width: 100%;
    }
  }

  &__download-pdf {
    @include interpolate(margin-top, 20px, 40px);
  }
}