$base-class: '.mol-ContentTextTypeOne';

@mixin useContentTextTypeOneVariants($variant) {
  @include useVariants($base-class, $variant) {
    @content;
  }
}

#{$base-class} {
  @include sectionSpacing;
  @include useDisableSpacing;

  &__label {
    @include font-h6;
    @include interpolate(margin-bottom, 10px, 20px);
  }

  &__heading {
    @include interpolate(margin-bottom, 20px, 40px);
    @include font-h2;
    max-width: 15em;
  }

  &__copy {
    @include interpolate(margin-top, -15px, -30px);
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;

    &__block {
      @include interpolate(margin-top, 15px, 30px);
      @include richText;

      &:not(:first-child:last-child) {
        @include breakpoint(medium) {
          flex: 0 1 47%;
        }
      }
    }
  }

  &__button-wrapper {
    @include interpolate(margin-top, 25px, 50px);
    display: flex;

    @include breakpoint(large) {
      margin-top: 0;
      justify-content: flex-end;
    }
  }
}