$base-class: '.mol-Footer';

@mixin useFooterVariants($variant) {
  @include useVariants($base-class, $variant) {
    @content;
  }
}

#{$base-class} {
  --background-color: #{$color-primary-dark};
  --color: #{$color-white};
  --authentic-credit-color: #{rgba($color-white, .8)};
  @include interpolate(padding-top, 55px, 110px);
  @include interpolate(padding-bottom, 40px, 80px);
  background-color: var(--background-color);
  color: var(--color);

  &__logo {
    line-height: 0;
    position: relative;
    @include interpolate(margin-bottom, 25px, 50px);

    @include breakpoint(large) {
      margin-bottom: 0;
    }

    @include breakpoint("xsmall", "max") {
      text-align: center;
    }

    &__link {
      display: inline-block;
      max-width: 100%;
    }

    &__image {
      @include interpolate(height, 108px, 216px);
      @include interpolate(width, 88px, 176px);
      background-size: contain;
      background-position: left center;
      background-repeat: no-repeat;
      max-width: 100%;
    }
  }

  &__navigation-bar {
    @include interpolate(margin-bottom, 25px, 50px);
    text-align: center;

    @include breakpoint(small) {
      margin-bottom: 0;
      text-align: left;
    }
  }

  &__items {
    @include interpolate(margin-top, -20px, -40px);
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
  }

  &__item {
    @include interpolate(margin-top, 20px, 40px);
    @include interpolate(padding-right, 15px, 30px);
    flex: 0 1 100%;

    @include breakpoint("xsmall", "max") {
      text-align: center;
      padding-right: 0;
    }

    @include breakpoint(medium) {
      flex: 0 1 50%;
    }

    @include breakpoint(large) {
      flex: 0 1 100%;
    }

    @include breakpoint(xlarge) {
      flex: 0 1 50%;
    }

    &--large &__copy {
      p {
        @include font-body-large;
      }
    }

    &__heading {
      @include font-h6;
      @include interpolate(margin-bottom, 6px, 12px);
    }

    &__copy {
      @include richText;
      color: $color-neutral-light;

      @include breakpoint("xsmall", "max") {
        @include maxWidth(20em);
      }

      a {
        @include link(light);
      }

      p {
        @include font-body-small;
      }
    }
  }

  &__social-networks {
    @include interpolate(margin-top, 20px, 40px);

    &__heading {
      @include font-h6;
      @include interpolate(margin-bottom, 5px, 10px);
    }

    &__items {
      @include interpolate((margin-left, margin-right), -3px, -6px);
      @include flexbox(center, flex-start);
    }

    &__item {
      @include interpolate((margin-left, margin-right), 3px, 6px);
      line-height: 0;
    }
  }

  &__authenticff-credit {
    @include interpolate(margin-top, 80px, 160px);

    @include breakpoint("xsmall", "max") {
      text-align: center;
    }

    &__link {
      @include transition(opacity);
      opacity: .6;

      &:hover {
        opacity: 1;
      }

      svg {
        fill: var(--authentic-credit-color);

        * {
          fill: inherit;
        }
      }

    }
  }
}