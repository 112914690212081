$base-class: '.atom-Hamburger';

@mixin useHamburgerVariants($variant) {
  @include useVariants($base-class, $variant) {
    @content;
  }
}

#{$base-class} {
  --icon-color: #{$color-secondary};
  $atom: &;
  display: inline-block;
  position: relative;
  cursor: pointer;

  &--light {
    --icon-color: #{$color-white};
  }

  &--active {
    --icon-color: #{$color-white};
  }

  &__icon {
    @include interpolate(margin-right, 5px, 20px);
    width: 28px;
    height: 20px;
    position: relative;
    display: block;
    transform-origin: center center;

    @include breakpoint("large", "max") {
      transform: scale(0.8);
    }

    @include breakpoint("small", "max") {
      transform: scale(0.7);
    }

    @include breakpoint("xsmall", "max") {
      transform: scale(0.6);
    }

    .top,
    .middle,
    .bottom {
      position: absolute;
      display: block;
      width: 100%;
      left: 0;
      line-height: 0;
      display: flex;
      transform: scaleX(1.2);
    }

    .line {
      height: 2px;
      display: inline-block;
      line-height: 0;
      width: 100%;
      background-color: var(--icon-color);
    }

    .top {
      top: 0;
      justify-content: flex-start;

      .line {
        transform-origin: top left;
      }
    }

    .middle {
      justify-content: flex-end;
      top: 50%;
      transform: translateY(-50%) scaleX(1.2);
    }

    .bottom {
      justify-content: flex-start;
      bottom: 0;

      .line {
        transform-origin: bottom left;
      }
    }
  }

  &--hover & {
    &__inner {
      transform: translate(calc-interpolation(-2px, -4px), calc-interpolation(-2px, -4px));
    }

    &__inner:after {
      transform: translate(calc-interpolation(4px, 8px), calc-interpolation(4px, 8px));
    }
  }

  a:hover &,
  &:hover {
    @extend #{$atom}--hover;
  }
}