$base-class: '.mol-ContentTextTypeThree';

@mixin useContentTextTypeThreeVariants($variant) {
  @include useVariants($base-class, $variant) {
    @content;
  }
}

#{$base-class} {
  --icon-color: #{$color-secondary};
  @include sectionSpacing;
  @include useDisableSpacing;

  &__label {
    @include font-h6;
    @include interpolate(margin-bottom, 10px, 20px);
  }

  &__heading {
    @include font-h2;
    max-width: 15em;

    &:after {
      content: "";
      @include interpolate(height, 1px, 3px);
      @include interpolate(width, 30px, 60px);
      @include interpolate(margin-top, 20px, 40px);
      display: block;
      background-color: var(--icon-color);
    }
  }

  &__copy {
    @include interpolate(margin-top, -15px, -30px);
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;

    &__block {
      @include interpolate(margin-top, 15px, 30px);
      @include richText;

      &:not(:first-child:last-child) {
        @include breakpoint(medium) {
          flex: 0 1 47%;
        }
      }
    }
  }

  &__button {
    @include interpolate(margin-top, 25px, 50px);
  }
}