$base-class: '.el-Breadcrumbs';

@mixin useBreadcrumbsVariants($variant) {
  @include useVariants($base-class, $variant) {
    @content;
  }
}

#{$base-class} {
  @include interpolate((padding-top, padding-bottom), 5px, 10px);

  &__items {
    display: flex;
    align-items: baseline;
    justify-content: flex-start;

    &:before {
      content: "\007C";
      font-size: 80%;
      display: inline-block;
      margin-right: 1.2em;
    }

    li {
      @include font-body-small;

      &:not(:last-child) {
        &:after {
          content: "\2022";
          font-size: 70%;
          margin-left: .3em;
          margin-right: .6em;
          transform: translateY(-.5em);
        }
      }

      a {
        @include link;

        .mol-HeroStandard &,
        .mol-HeroComplex & {
          @include link(light);
        }
      }
    }
  }
}