$base-class: '.mol-HeroStandard';

@mixin useHeroStandardVariants($variant) {
  @include useVariants($base-class, $variant) {
    @content;
  }
}

#{$base-class} {
  --background-color: #{$color-primary};
  --color: #{$color-white};
  --overlay-color: #{rgba($color-neutral-darkest, .5)};
  background-color: var(--background-color);
  color: var(--color);
  position: relative;
  display: flex;
  align-items: stretch;

  &:before {
    content: "";
    padding-top: 30%;
  }

  &__wrapper {
    @include useHeaderSpacing;
    flex: 1;
    display: flex;
    align-items: stretch;
  }

  &__media {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    &__inner {
      position: relative;
      height: 100%;
      width: 100%;

      &:after {
        content: "";
        background-color: var(--overlay-color);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 0;
      }
    }
  }

  &__inner {
    @include sectionSpacing(small);
    position: relative;
    flex: 1;
    z-index: 3;
    display: flex;
    flex-direction: column;

    .grid-container {
      height: 100%;
      display: flex;
      flex-direction: column;

      .row {
        width: 100%;
      }
    }
  }

  &__content-wrapper {
    flex: 1;
  }

  &__content {
    @include interpolate((padding-top, padding-bottom), 15px, 30px);
    display: flex;
    align-items: center;
  }

  &__breadcrumbs {
    @include interpolate(margin-bottom, 5px, 10px);
  }

  &__heading {
    @include font-h1;
    max-width: 12em;
  }

  &__copy {
    @include font-body;
    @include interpolate(margin-top, 15px, 30px);
    max-width: 35em;
  }
}