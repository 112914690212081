$base-class: '.el-Iframe';

@mixin useIframeVariants($variant) {
  @include useVariants($base-class, $variant) {
    @content;
  }
}

#{$base-class} {
  --play-button-background-color: #{$color-secondary};
  --play-button-icon-color: #{$color-white};
  position: relative;

  &__spacer {
    @include aspectRatio(16, 9);
  }

  &__iframe,
  &__cover-image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &__cover-image {
    @include backgroundImage;
    @include transition(opacity);
    opacity: 1;
    visibility: visible;
    cursor: pointer;

    [data-active="true"] & {
      visibility: hidden;
      opacity: 0;
    }
  }

  &__play-button {
    @include interpolate((width, height), 45px, 90px);
    left: 50%;
    bottom: 50%;
    transform: translate(-50%, 50%);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    background-color: var(--play-button-background-color);
    z-index: 4;
    cursor: pointer;

    &__icon {
      display: block;
      @include interpolate((width, height), 10px, 20px);
    }

    svg {
      height: 100%;
      width: 100%;
      fill: var(--play-button-icon-color);
      display: block;

      * {
        fill: inherit;
      }
    }
  }
}