$base-class: '.el-CardUnitType';

@mixin useCardUnitTypeVariants($variant) {
  @include useVariants($base-class, $variant) {
    @content;
  }
}

#{$base-class} {
  $self: &;
  --label-icon-color: #{$color-secondary};
  --border-color: #{$color-secondary};
  --background-color: #{$color-neutral-light};
  --image-background-color: #{$color-neutral-lightest};
  --image-overlay-color: #{rgba($color-secondary, .75)};
  --image-overlay-icon-color: #{$color-white};
  @include transition((border-color, background-color));
  display: block;
  border: 1px solid var(--border-color);
  background-color: var(--background-color);

  &__image {
    @include transition(border-color);
    border-bottom: 1px solid var(--border-color);
    background-color: var(--image-background-color);
    position: relative;

    &__inner {
      @include backgroundImage(contain);
      position: absolute;
      top: 7%;
      left: 7%;
      height: 86%;
      width: 86%;
    }

    &:before {
      @include transition(opacity);
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: var(--image-overlay-color);
      opacity: 0;
    }

    &__spacer {
      @include aspectRatio(1, 1);

      &:before,
      &:after {
        content: "";
        display: block;
        @include interpolate(width, 30px, 60px);
        @include interpolate(height, 1px, 2px);
        background-color: var(--image-overlay-icon-color);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 0;
        @include transition(opacity, $delay: .15s);
      }

      &:after {
        transform: translate(-50%, -50%) rotate(-90deg);
      }
    }
  }

  &__content {
    @include interpolate((padding-top, padding-bottom), 13px, 26px);
    @include interpolate((padding-left, padding-right), 13px, 26px);
  }

  &__heading {
    @include font-h5;
  }

  &__copy {
    @include font-body-small;
    @include interpolate(margin-top, 5px, 10px);
  }

  &__labels {
    @include interpolate(margin-top, 10px, 20px);
    @include interpolate((margin-left, margin-right), -5px, -10px);
  }

  &__label {
    @include font-body-xsmall;
    @include flexbox(center, flex-start);
    @include interpolate((padding-left, padding-right), 5px, 10px);

    &+& {
      @include interpolate(margin-top, 3px, 6px);
    }

    &__icon {
      @include interpolate(margin-right, 5px, 10px);
      @include interpolate((width, height), 12px, 24px);
      display: block;

      svg {
        fill: var(--label-icon-color);
        height: 100%;
        width: 100%;

        * {
          fill: inherit;
        }
      }
    }

    &__text {
      display: block;
    }
  }

  &__button {
    @include interpolate(margin-top, 20px, 40px);
  }

  &--hover {
    --background-color: #{$color-neutral-lightest};
    --border-color: #{rgba($color-secondary, .6)};
  }

  &--hover & {
    &__image {
      &:before {
        opacity: 1;
      }

      &__spacer {

        &:before,
        &:after {
          opacity: 1;
        }
      }
    }
  }

  &:is(a):hover {
    @extend #{$self}--hover;
  }
}