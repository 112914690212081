$base-class: '.mol-MediaDuo';

@mixin useMediaDuoVariants($variant) {
  @include useVariants($base-class, $variant) {
    @content;
  }
}

#{$base-class} {
  @include sectionSpacing;
  @include useDisableSpacing;

  &__items {
    @include interpolate((margin-left, margin-right), -2px, -4px);
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &__item {
    @include interpolate(padding, 2px, 4px);
    flex: 1 1 100%;

    &__inner {
      @include aspectRatio(1, 1);
      position: relative;
    }

    @include breakpoint(small) {
      flex: 0 1 50%;
    }
  }

  &__image {
    @include backgroundImage;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}