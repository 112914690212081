$base-class: '.mol-HeroBasic';

@mixin useHeroBasicVariants($variant) {
  @include useVariants($base-class, $variant) {
    @content;
  }
}

#{$base-class} {
  --background-color: transparent;
  --color: #{$color-black};
  @include sectionSpacing(small);
  background-color: var(--background-color);
  color: var(--color);

  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 35%;
    background-image: url(/built/images/shape-light.svg);
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top right;
    z-index: -1;
  }

  &__breadcrumbs {
    @include interpolate(margin-bottom, 5px, 10px);
  }

  &__heading {
    @include font-h1;
    max-width: 12em;
  }

  &__copy {
    @include font-body;
    @include interpolate(margin-top, 15px, 30px);
    max-width: 35em;
  }
}