$base-class: '.el-CardFeature';

@mixin useCardFeatureVariants($variant) {
  @include useVariants($base-class, $variant) {
    @content;
  }
}

#{$base-class} {
  --icon-color: #{$color-white};
  --background-color: #{$color-primary};
  --color: #{$color-white};
  background-color: var(--background-color);
  color: var(--color);
  @include interpolate((padding-top, padding-bottom), 40px, 80px);
  @include interpolate((padding-left, padding-right), 25px, 50px);

  &__icon {
    @include interpolate((width, height), 45px, 90px);
    @include interpolate(margin-bottom, 15px, 30px);
    display: flex;
    align-items: center;
    justify-content: flex-start;

    svg {
      width: 100%;
      height: 100%;
      fill: var(--icon-color);

      * {
        fill: inherit;
        opacity: 1;

      }
    }
  }

  &__heading {
    @include font-h4;
  }

  &__copy {
    @include font-body-small;
    @include interpolate(margin-top, 10px, 20px);
  }
}