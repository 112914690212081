$base-class: '.el-Map';

@mixin useMapVariants($variant) {
  @include useVariants($base-class, $variant) {
    @content;
  }
}

#{$base-class} {
  --popup-background-color: #{$color-white};
  --popup-arrow-color: #{$color-white};
  --popup-color: #{$color-black};
  --popup-close-button-background-color: #{$color-primary};
  --popup-close-button-color: #{$color-white};
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  .mapboxgl-popup {
    max-width: 400px !important;
    filter: drop-shadow(0 0px 20px rgba(104, 104, 104, 0.1));

    .mapboxgl-popup-content {
      @include interpolate(border-radius, 2px, 4px);
      background-color: var(--popup-background-color);
      overflow: hidden;
      padding: 0;
      border-radius: 0;
      border: none;
    }

    .mapboxgl-popup-tip {
      @include interpolate((border-left-width, border-right-width), 5px, 10px);
      @include interpolate((border-top-width, border-bottom-width), 5px, 10px);
    }

    .mapboxgl-popup-close-button {
      @include interpolate((width, height, border-radius), 8px, 16px);
      @include interpolate((top, right), 3px, 6px);
      font-family: "Arial";
      font-size: 1.1em;
      line-height: .8;
      color: var(--popup-close-button-color);
      background-color: var(--popup-close-button-background-color);
      opacity: 1;
      transition: opacity 0.25s ease;

      &:hover {
        opacity: 1;
      }
    }
  }

  .mapboxgl-ctrl-attrib,
  .mapboxgl-ctrl-logo {
    display: none;
  }

  &__marker {
    &__popup {
      @include interpolate((padding-left, padding-right), 6px, 12px);
      @include interpolate((padding-top, padding-bottom), 10px, 20px);
      @include interpolate(width, 180px, 280px);

      &__content {
        align-self: center;
      }

      &__content {
        @include interpolate((padding-left, padding-right), 5px, 10px);
      }

      &__heading {
        @include font-body;
        font-weight: $font-weight-bold;
        line-height: 1.2;
      }

      &__copy {
        @include font-body-xsmall;
        @include interpolate(margin-top, 0px, 5px);
        line-height: 1.4;
      }
    }
  }
}