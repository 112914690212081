$base-class: '.atom-ButtonIcon';

@mixin useButtonIconVariants($variant) {
  @include useVariants($base-class, $variant) {
    @content;
  }
}

#{$base-class} {
  --background-color: #{$color-primary};
  --icon-color: #{$color-white};
  $self: &;
  display: inline-block;
  position: relative;

  &--light {
    --background-color: #{$color-white};
    --icon-color: #{$color-black};
  }

  &--hover {
    --background-color: #{$color-neutral};

    &#{$self}--light {
      --background-color: #{$color-neutral};
    }
  }

  &__inner {
    @include interpolate((width, height), 15px, 30px);
    @include transition((background-color, color));
    border-radius: 50%;
    display: block;
    position: relative;
    background-color: var(--background-color);
  }

  &__icon {
    position: absolute;
    display: block;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transform-origin: center center;
    z-index: 3;
    @include interpolate((width, height), 9px, 18px);

    svg {
      width: 100%;
      height: 100%;
      fill: var(--icon-color);

      * {
        fill: inherit;
      }
    }
  }

  &--hover {}

  a:hover &,
  &:hover {
    @extend #{$self}--hover;
  }
}