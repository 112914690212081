// grid
$grid-columns: 12 !default; // Set number of columns in the grid
$grid-max-width: 100rem !default; // Set the max-width of the content well

// default gutter sizes for columns
$gutter-size-xsmall: .75rem !default;
$gutter-size-small: 1rem !default;
$gutter-size-medium: 1.25rem !default;
$gutter-size-large: 1.5rem !default;
$gutter-size-xlarge: 1.75rem !default;
$gutter-size-xxlarge: 2rem !default;

// left and right page padding
$grid-padding-size-xxsmall: 1rem !default;
$grid-padding-size-xsmall: 1.5rem !default;
$grid-padding-size-small: 2rem !default;
$grid-padding-size-medium: 2.5rem !default;
$grid-padding-size-large: 3rem !default;
$grid-padding-size-xlarge: 4rem !default;
$grid-padding-size-xxlarge: 5rem !default;

// breakpoint settings
$bp-xxs: 420px;
$bp-xs: 520px;
$bp-s: 768px;
$bp-m: 1024px;
$bp-d: 1280px;
$bp-l: 1440px;
$bp-xl: 1600px;
$bp-xxl: 1920px;