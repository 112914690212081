$base-class: '.mol-ContentTextMedia';

@mixin useContentTextMediaVariants($variant) {
  @include useVariants($base-class, $variant) {
    @content;
  }
}

#{$base-class} {
  @include sectionSpacing;
  @include useDisableSpacing;

  &__inner {
    align-items: flex-start;
  }

  &__content {
    @include interpolate(margin-top, 20px, 40px);
    align-self: center;

    @include useContentTextMediaVariants(carousel-right) {
      @include interpolate(padding-right, 20px, 40px);
    }

    @include useContentTextMediaVariants(carousel-left) {
      @include interpolate(padding-left, 20px, 40px);
    }

    @include breakpoint(medium) {
      margin-top: 0;
    }

    @include breakpoint(xlarge) {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    @include breakpoint("small", "max") {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }

  &__label {
    @include font-h6;
    @include interpolate(margin-bottom, 10px, 20px);
  }

  &__heading {
    @include font-h2;
    max-width: 10em;

    @include breakpoint(small, "max") {
      max-width: 15em;
    }
  }

  &__copy {
    @include interpolate(margin-top, 15px, 30px);
    @include richText;
  }

  &__button {
    @include interpolate(margin-top, 20px, 40px);
  }

  &__media {
    @include breakpoint("small", "max") {
      order: -1;
    }
  }

  &__carousel {
    @include aspectRatio(1, 1);
    position: relative;
    @include useCarousel(false);

    @include useContentTextMediaVariants(useCarousel) {
      @include useCarousel(true);
    }

    @include useContentTextMediaVariants(carousel-square) {
      @include aspectRatio(1, 1)
    }

    @include useContentTextMediaVariants(carousel-portrait) {
      @include aspectRatio(3, 4)
    }

    @include useContentTextMediaVariants(carousel-landscape) {
      @include aspectRatio(4, 3)
    }

    @include useContentTextMediaVariants(carousel-left) {
      @include useCarouselNav("left");

      @include breakpoint("small", "max") {
        @include useCarouselNav("right");
      }
    }

    @include useContentTextMediaVariants(carousel-right) {
      @include useCarouselNav("right");
    }
  }
}