$base-class: '.mol-MapStandard';

@mixin useMapStandardVariants($variant) {
  @include useVariants($base-class, $variant) {
    @content;
  }
}

#{$base-class} {
  --background-color: #{$color-neutral-light};
  @include sectionSpacing;
  @include useDisableSpacing;
  position: relative;

  &__map {
    @include aspectRatio(16, 9);
    background-color: var(--background-color);
    position: relative;
  }
}