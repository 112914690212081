$base-class: '.el-MediaTakeover';

@mixin useMediaTakeoverVariants($variant) {
  @include useVariants($base-class, $variant) {
    @content;
  }
}

#{$base-class} {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;

  &__inner {
    position: relative;
    height: 100%;
    width: 100%;
  }

  &__image {
    @include backgroundImage;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &__video {
    position: relative;
    height: 100%;
    width: 100%;
  }
}