$base-class: '.mol-CallToActionForm';

@mixin useCallToActionFormVariants($variant) {
  @include useVariants($base-class, $variant) {
    @content;
  }
}

#{$base-class} {
  --background-color: #{$color-primary};
  --color: #{$color-white};
  @include sectionSpacing;
  @include useDisableSpacing;

  &__inner {
    @include sectionSpacing;
    background-color: var(--background-color);
    color: var(--color);
    position: relative;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-image: url(/built/images/pattern.svg);
      opacity: .12;
      @include interpolate(background-size, 700px, 1400px);
      background-repeat: repeat;
    }
  }

  &__content {
    @include interpolate(padding-right, 15px, 30px);
  }

  &__label {
    @include font-h6;
    @include interpolate(margin-bottom, 10px, 20px);
  }

  &__heading {
    @include font-h1;
    max-width: 15em
  }

  &__copy {
    @include interpolate(margin-top, 15px, 30px);
    @include richText;
    max-width: 40em;

	a {
		font-weight: 700;
		color: $color-white;
		transition: opacity 300ms ease;
		
		&:hover {
			color: $color-white;
			opacity: 0.6;
		}

	}

  }

  &__form {
    @include interpolate(margin-top, 30px, 60px);

    @include breakpoint(medium) {
      margin-top: 0;
    }
  }
}