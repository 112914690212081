$base-class: '.mol-ListingUnits';

@mixin useListingUnitsVariants($variant) {
  @include useVariants($base-class, $variant) {
    @content;
  }
}

#{$base-class} {
  --chart-background-color: transparent;
  --header-background-color: #{$color-primary};
  --header-color: #{$color-white};
  --cell-color: #{$color-black};
  --cell-border-color: #{rgba($color-primary, .2)};
  --unit-title-background-color: #{rgba($color-primary, .75)};
  --unit-title-color: #{$color-white};
  @include sectionSpacing;
  @include useDisableSpacing;

  .mol-UnitTypeInformation+& {
    padding-top: 0;
  }

  &__introduction {
    @include interpolate(margin-bottom, 35px, 70px);
  }

  &__label {
    @include font-h6;
    @include interpolate(margin-bottom, 10px, 20px);
  }

  &__heading {
    @include font-h2;
    max-width: 15em;
  }

  &__copy {
    @include font-body;
    @include interpolate(margin-top, 15px, 30px);
    max-width: 40em;
  }

  &__button {
    @include interpolate(margin-top, 20px, 40px);
  }

  &__chart {
    // @include interpolate(border-radius, 5px, 10px);
    overflow: hidden;
    background-color: var(--chart-background-color);

    &__header,
    &__item {
      @include interpolate((padding-left, padding-right), 4px, 8px);
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      &__property,
      &__details,
      &__heading {
        @include interpolate((padding-top, padding-bottom), 10px, 20px);
        @include interpolate((padding-left, padding-right), 7px, 14px);
      }

      &__property {
        &[data-property="button"] {
          text-align: right;
        }

        &[data-property="title"] {
          width: 18%;

          @include breakpoint("medium", "max") {
            width: 25%;
          }
        }

        &[data-property="button"] {
          margin-left: auto;
          flex: 1;
        }

        &[data-property="bedrooms"] {
          width: 10%;
        }

        &[data-property="bathrooms"] {
          width: 10%;
        }

        &[data-property="squareFeet"] {
          width: 16%;
        }

        &[data-property="unitType"] {
          width: 20%;
        }

        @include breakpoint("medium", "max") {
          &:not([data-property="title"], [data-property="button"], [data-property="details"]) {
            display: none;
          }
        }
      }
    }

    &__header {
      @include font-body-small;
      background-color: var(--header-background-color);
      color: var(--header-color);
      font-weight: $font-weight-bold;

      &__property {
        @include breakpoint("medium", "max") {
          display: none;
        }
      }

      &__heading {
        display: none;
        flex: 1;

        @include breakpoint("medium", "max") {
          display: block;
        }
      }
    }

    &__item {
      @include font-body-small;
      align-items: flex-start;
      color: var(--cell-color);

      &:not(:last-child) {
        border-bottom: 1px solid var(--cell-border-color);
      }

      &[data-hidden] {
        display: none;
      }

      &[data-unavailable] {
        opacity: .6;
      }

      &__details {
        display: none;
        flex: 1;

        @include breakpoint("medium", "max") {
          display: flex;
        }

        @include breakpoint("xsmall", "max") {
          order: 100;
          flex: 0 1 100%;
        }
      }

      &__property {
        &[data-property="title"] &__text {
          @include interpolate((padding-top, padding-bottom), 2px, 4px);
          @include interpolate((padding-left, padding-right), 6px, 12px);
          @include font-body-small;
          display: inline-block;
          background-color: var(--unit-title-background-color);
          color: var(--unit-title-color);
        }
      }

      &__button {}
    }
  }
}