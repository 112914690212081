$base-class: '.mol-FloorPlansViewer';

@mixin useFloorPlansViewerVariants($variant) {
  @include useVariants($base-class, $variant) {
    @content;
  }
}

#{$base-class} {
  --information-heading-color: #{$color-black};
  --information-subheading-color: #{$color-primary};
  --information-copy-color: #{$color-black};
  --indicator-active-color: #{$color-primary};
  --indicator-color: #{rgba($color-primary, .5)};
  --selector-border-color: #{$color-primary};
  --selector-color: #{$color-primary};
  --marker-background-color: #{$color-secondary};
  --marker-available-background-color: #{$color-primary};
  --marker-inactive-background-color: #{$color-black};
  @include sectionSpacing;
  @include useDisableSpacing;

  &__information {
    @include interpolate(margin-bottom, 30px, 60px);

    @include breakpoint(large) {
      margin-bottom: 0;
    }

    @include breakpoint("medium", "max") {
      display: flex;
      align-items: flex-start;
    }

    @include breakpoint("xsmall", "max") {
      display: block;
    }

    &__carousel {
      @include breakpoint("medium", "max") {
        flex: 0 1 40%;
      }

      &__inner {
        @include useCarousel;
        @include aspectRatio(4, 3);
      }
    }

    &__content {
      @include interpolate(margin-left, 25px, 50px);
      @include interpolate(margin-top, 15px, 30px);

      @include breakpoint("medium", "max") {
        flex: 1;

        @include breakpoint("small") {
          margin-top: 0;
        }
      }

      @include breakpoint(large) {
        margin-left: 0;
      }

      @include breakpoint("xsmall", "max") {
        margin-left: 0;
      }

    }

    &__heading {
      @include font-h4;
      color: var(--information-heading-color);
    }

    &__subheading {
      @include interpolate(margin-top, 10px, 20px);
      @include font-body-small;
      color: var(--information-subheading-color);
    }

    &__copy {
      @include interpolate(margin-top, 10px, 20px);
      @include richText;
      color: var(--information-copy-color);

      p {
        @include font-body-small;
      }
    }
  }

  &__floorplans {
    &__nav {
      @include flexbox;
      @include interpolate(margin-bottom, 20px, 40px);

      &__selectors {
        @include interpolate((margin-left, margin-right), -5px, -10px);
        @include interpolate(margin-top, -5px, -10px);
        flex: 1;
      }

      select {
        appearance: none;
      }

      &__selector {
        @include interpolate((padding-top, padding-bottom), 8px, 16px);
        @include interpolate(padding-left, 10px, 20px);
        @include interpolate(padding-right, 20px, 40px);
        @include interpolate((margin-left, margin-right), 5px, 10px);
        @include interpolate(margin-top, 5px, 10px);
        @include transition(opacity, .2s);
        @include font-body-small;
        @include interpolate(background-size, 8px, 12px);
        line-height: 1;
        background-image: url('/built/icons/arrow-down.svg');
        background-position: right 1em center;
        background-repeat: no-repeat;
        border: 1px solid var(--selector-border-color);
        opacity: .5;
        border-radius: 2px;
        color: var(--selector-color);

        &[data-floorplans-selector-selected="true"] {
          opacity: 1;
        }
      }

      &__reset {
        @include interpolate(margin-left, 20px, 40px);
        flex: none;
        align-self: flex-start;
      }
    }

    &__wrapper {
      @include flexbox(flex-start);
    }

    &__indicator {
      @include interpolate(margin-right, 40px, 80px);
      @include interpolate(width, 50px, 100px);
      text-align: center;

      @include breakpoint("small", "max") {
        display: none;
      }

      &__labels {
        @include interpolate(margin-top, 3px, 6px);
      }

      &__label {
        white-space: nowrap;
        @include font-body-small;

        &[data-floor-active="false"] {
          display: none;
        }

        &[data-floor-active="true"] {
          display: block;
        }
      }

      &__icons {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
      }

      &__icon {
        @include interpolate(width, 30px, 40px);
        @include interpolate(margin-top, -3px, -4px);

        svg {
          fill: var(--indicator-color);
          transition: fill .2s ease;

          * {
            fill: inherit;
          }
        }

        &[data-floor-active="true"] {
          svg {
            fill: var(--indicator-active-color);
          }
        }

      }
    }

    &__inner {
      flex: 1;
    }
  }

  // Old Amplify
  &__floorplan-imagesContainer {
    margin-left: auto;
    margin-right: auto;
  }

  &__floorplan-image-container {
    display: none;
    position: relative;

    &:first-child {
      display: block;
    }
  }

  &__floorplan-image {
    width: 100%;
    transform: scale(1.05);
  }

  &__unit-marker {
    @include interpolate((width, height), 15px, 30px);
    position: absolute;
    cursor: pointer;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-style: solid;
    background-color: var(--marker-background-color);
    transition: opacity 300ms ease;
    z-index: 1;
    opacity: 0;
    transform: scale(0);
    cursor: pointer;

    &:hover,
    &#{$base-class}__popupMarker--active {
      opacity: 1;
    }

    &--available {
      background-color: var(--marker-available-background-color);
    }

    &--inactive {
      pointer-events: none;
      opacity: .5 !important;
      background-color: var(--marker-inactive-background-color)
    }

  }

  .tippy-box[data-theme~='floorplansviewer-popup'] {
    background-color: $color-neutral-light;
    color: $color-black;
    padding: 0;
    filter: drop-shadow(0 0px 20px rgba(104, 104, 104, 0.3));
    @include interpolate(border-radius, 2px, 4px);
    background-color: $color-neutral-light;
    max-width: 80%;

    .tippy-content {
      width: 45em;
      max-width: 70vw;
      padding: 0;

      @include breakpoint("small", "max") {
        width: auto;
        width: 20em;
        max-width: 50vw;
      }
    }
  }

  .tippy-box[data-theme~='floorplansviewer-popup'][data-placement^='top']>.tippy-arrow::before {
    border-top-color: $color-neutral-light;
  }

  .tippy-box[data-theme~='floorplansviewer-popup'][data-placement^='bottom']>.tippy-arrow::before {
    border-bottom-color: $color-neutral-light;
  }

  .tippy-box[data-theme~='floorplansviewer-popup'][data-placement^='left']>.tippy-arrow::before {
    border-left-color: $color-neutral-light;
  }

  .tippy-box[data-theme~='floorplansviewer-popup'][data-placement^='right']>.tippy-arrow::before {
    border-right-color: $color-neutral-light;
  }
}