$base-class: '.mol-CallToActionFormInset';

@mixin useCallToActionFormInsetVariants($variant) {
  @include useVariants($base-class, $variant) {
    @content;
  }
}

#{$base-class} {
  --background-color: #{$color-primary};
  --color: #{$color-white};
  --shape-color: #{$color-neutral-light};
  --overlay-color: #{rgba($color-neutral-darkest, .5)};
  @include sectionSpacing;
  @include useDisableSpacing;
  overflow: hidden;

  &__inner {
    @include sectionSpacing(large);
    position: relative;

    &:before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      max-height: 50%;
      width: 100%;
      z-index: -1;
      @include interpolate(height, 160px, 320px);
      background-color: var(--shape-color);
    }

    &:after {
      content: "";
      background-color: var(--background-color);
      z-index: -1;
    }
  }

  &__inner:after,
  &__media {
    $offsetPercent: (1 / 12) * 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;

    @include breakpoint(small) {
      left: calc(#{$offsetPercent} + (#{$gutter-size-small} / 2));
    }

    @include breakpoint(medium) {
      left: calc(#{$offsetPercent} + (#{$gutter-size-medium} / 2));
    }

    @include breakpoint(large) {
      left: calc(#{$offsetPercent} + (#{$gutter-size-large} / 2));
    }

    @include breakpoint(xlarge) {
      left: calc(#{$offsetPercent} + (#{$gutter-size-xlarge} / 2));
    }

    @include breakpoint(xxlarge) {
      left: calc(#{$offsetPercent} + (#{$gutter-size-xxlarge} / 2));
    }
  }

  &__media {
    &__inner {
      position: relative;
      height: 100%;
      width: 100%;

      &:after {
        content: "";
        background-color: var(--overlay-color);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 2;
      }
    }
  }

  &__image,
  &__video {
    z-index: 1;
  }

  &__image {
    @include backgroundImage;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &__video {
    position: relative;
    height: 100%;
    width: 100%;
  }

  &__content {
    color: var(--color);
    position: relative;
    z-index: 2;
  }

  &__label {
    @include font-h6;
    @include interpolate(margin-bottom, 10px, 20px);
  }

  &__heading {
    @include font-h2;
    max-width: 15em;
  }

  &__form {
    @include interpolate(margin-top, 30px, 60px);
  }
}