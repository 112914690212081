$base-class: '.mol-ListingInfo';

@mixin useListingInfoVariants($variant) {
  @include useVariants($base-class, $variant) {
    @content;
  }
}

#{$base-class} {
  @include sectionSpacing;
  @include useDisableSpacing;

  &__introduction {
    @include interpolate(margin-bottom, 35px, 70px);
  }

  &__label {
    @include font-h6;
    @include interpolate(margin-bottom, 10px, 20px);
  }

  &__heading {
    @include font-h2;
    max-width: 15em;
  }

  &__copy {
    @include font-body;
    @include interpolate(margin-top, 15px, 30px);
    max-width: 40em;
  }

  &__button {
    @include interpolate(margin-top, 20px, 40px);
  }

  &__items {
    @include interpolate(margin-top, -25px, -50px);
  }

  &__item {
    @include interpolate(margin-top, 25px, 50px);
  }
}