$base-class: '.el-Form';

@mixin useFormVariants($variant) {
  @include useVariants($base-class, $variant) {
    @content;
  }
}

#{$base-class} {
  --label-color: #{$color-black};
  --field-background-color: transparent;
  --field-color: #{$color-primary};
  --field-color-focus: #{$color-black};
  --field-placeholder-color: #{rgba($color-primary, .6)};
  --field-border-color: #{$color-primary};
  --field-color-required: #{$color-error};
  --field-color-error: #{$color-error};
  --alert-background-color: #{rgba($color-primary, .2)};
  --alert-color: #{$color-primary};
  --alert-error-background-color: #{rgba($color-error, .2)};
  --alert-error-color: #{$color-error};
  --checkmark-icon-url: url('/built/icons/checkmark.svg');

  &--light {
    --label-color: #{$color-white};
    --field-background-color: transparent;
    --field-color: #{$color-white};
    --field-color-focus: #{rgba($color-white, .8)};
    --field-placeholder-color: #{rgba($color-white, .6)};
    --field-border-color: #{$color-white};
    --field-color-required: #{$color-error};
    --field-color-error: #{lighten($color-error, 20)};
    --alert-background-color: #{rgba($color-white, .4)};
    --alert-color: #{$color-white};
    --alert-error-background-color: #{rgba(lighten($color-error, 20), .3)};
    --alert-error-color: #{$color-white};
    --checkmark-icon-url: url('/built/icons/checkmark-white.svg');
  }

  @mixin reset {
    div[id^='__lpform_'] {
      display: none;
    }

    input,
    textarea,
    select {
      width: 100%;
    }

    .fui-field,
    .fui-row,
    .fui-btn-container {
      margin-top: 0;
      margin-bottom: 0;
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  @mixin label {
    @include font-body-small;
    line-height: .9;
  }

  @mixin fieldFont() {
    @include font-body-small;
    line-height: 1;
  }

  @mixin field($directInput: false) {
    @include interpolate((padding-top, padding-bottom), 8px, 16px);
    @include interpolate((padding-left, padding-right), 10px, 20px);
    background-color: var(--field-background-color);
    outline: none !important;
    box-shadow: none !important;
    border: 1px solid var(--field-border-color);
    line-height: 1;
    border-radius: 2px;
    @include fieldFont;

    @if $directInput !=false {
      @include fieldFont;
    }

    &:focus {
      border-color: var(--field-color-focus);
      color: var(--field-color-focus);
    }

    &:-webkit-autofill:focus {
      -webkit-text-fill-color: var(--field-color-focus);
    }
  }

  [data-custom-formie-submit-button] {
    display: none;
  }

  &__form {
    @include reset;
    @include font-body;
    color: var(--field-color);
    $form: &;
    --fui-row-gutter: .5em;
    --fui-check-label-padding-left: 2em;
    --fui-label-margin: .5em;
    --fui-check-label-line-height: 1.4;
    --fui-check-margin-bottom: .5em;
    --fui-label-error-color: var(--label-color);
    --fui-error-color: var(--field-color-error);
    --fui-input-error-color: var(--field-color);
    --fui-input-error-border-color: var(--field-color-error);

    .fui-label,
    .fui-legend {
      @include label;
      color: var(--label-color);
    }

    .fui-label.fui-error {
      color: var(--label-color) !important;
    }

    .fui-row:not(.fui-row-empty) {
      @include interpolate(margin-top, 5px, 10px);

      &:first-child {
        margin-top: 0;
      }

      &:last-child .fui-field {
        margin-bottom: 0;
      }
    }

    .fui-field {}

    .fui-input {
      @include field(false);

      &::placeholder {
        color: var(--field-placeholder-color);
      }
    }

    .fui-error-message {
      @include font-body-small;
    }

    .fui-select {
      @include field;
      color: rgba(var(--field-color), .5);

      &.value-selected {
        color: var(--field-color);
      }

      &:focus {
        color: rgba(var(--field-color-focus), .5);

        &.value-selected {
          color: var(--field-color-focus);
        }

      }
    }

    textarea {
      resize: none;
    }

    .fui-checkbox,
    .fui-radio {
      @include fieldFont;
    }

    .fui-checkbox-label {

      &:before,
      &:after {
        content: '';
        @include interpolate((width, height), 10px, 15px);
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        display: block;
      }

      &:before {
        background-image: none !important;
        background-color: transparent !important;
        box-shadow: none;
        border: 1px solid var(--field-color);
        outline: none !important;
        box-shadow: none !important;
        border-radius: 0;
      }

      &:after {
        background-image: var(--checkmark-icon-url);
        background-size: contain;
        background-repeat: no-repeat;
        display: none;
        transform: scale(1.4) translateY(-30%);
      }
    }

    .fui-checkbox-input {
      &:checked+.fui-checkbox-label {
        &:before {
          border-color: var(--field-color);
        }

        &:after {
          display: block;
        }
      }

      &:focus+.fui-checkbox-label {
        color: var(--field-color-focus);

        &:before {
          border-color: var(--field-color-focus);
        }
      }
    }

    .fui-radio-label {
      &:before {
        content: '';
        @include interpolate((width, height), 10px, 15px);
        outline: none !important;
        box-shadow: none !important;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        display: block;
        background-image: none !important;
        background-color: transparent;
        box-shadow: none;
        border: 1px solid var(--field-color);
      }
    }

    .fui-radio-input {
      &:checked+.fui-radio-label {
        &:before {
          border-color: var(--field-color);
          background-color: var(--field-color);
        }
      }

      &:focus+.fui-radio-label {
        color: var(--field-color-focus);

        &:before {
          border-color: var(--field-color-focus);
          background-color: var(--field-color-focus);
        }
      }
    }

    .fui-required {
      color: var(--field-color-required);
    }

    .fui-error {
      color: var(--field-color-error);
    }

    .fui-submit {
      display: none;
    }

    .fui-btn-wrapper {
      @include interpolate(margin-top, 20px, 40px);
      padding: 0;
      margin: 0;
    }
  }

  .fui-alert {
    @include interpolate((padding-top, padding-bottom), 5px, 10px);
    @include interpolate((padding-left, padding-right), 10px, 20px);
    @include interpolate(border-radius, 15px, 30px);
    @include font-body-small;
    background-color: var(--alert-background-color);
    color: var(--alert-color);
    text-align: center;
    margin-bottom: 2em;

    &.fui-alert-error {
      background-color: var(--alert-error-background-color);
      color: var(--alert-error-color);
    }
  }
}