$base-class: '.el-CardInfo';

@mixin useCardInfoVariants($variant) {
  @include useVariants($base-class, $variant) {
    @content;
  }
}

#{$base-class} {
  $self: &;
  --border-color: #{$color-secondary};
  --content-background-color: #{$color-neutral-light};
  @include transition(border-color);
  display: block;
  border: 1px solid var(--border-color);

  &__image {
    @include backgroundImage;

    &__spacer {
      @include aspectRatio(4, 3);
    }
  }

  &__content {
    @include interpolate((padding-top, padding-bottom), 13px, 26px);
    @include interpolate((padding-left, padding-right), 13px, 26px);
    @include transition(background-color);
    background-color: var(--content-background-color);
  }

  &__heading {
    @include font-h5;
  }

  &__copy {
    @include font-body-small;
    @include interpolate(margin-top, 10px, 20px);
  }

  &--hover {
    --content-background-color: #{$color-neutral-lightest};
    --border-color: #{rgba($color-secondary, .6)};
  }

  &:is(a):hover {
    @extend #{$self}--hover;
  }
}