$base-class: '.el-Carousel';

@mixin useCarouselVariants($variant) {
  @include useVariants($base-class, $variant) {
    @content;
  }
}

@mixin useCarousel($use-nav: false) {
  position: relative;

  @if $use-nav==true {
    @include interpolate(margin-bottom, 30px, 60px);
  }

  .el-Carousel {
    display: block;

    @if $use-nav==true {
      .el-Carousel__nav {
        @include interpolate(height, 30px, 60px);
      }
    }
  }
}

@mixin useCarouselNav($nav-position: "center") {
  .el-Carousel__nav {
    @if $nav-position=="center" {
      justify-content: center;
    }

    @if $nav-position=="left" {
      justify-content: flex-start;
    }

    @if $nav-position=="right" {
      justify-content: flex-end;
    }
  }
}

#{$base-class} {
  --arrow-color: #{$color-secondary};
  --dots-color: #{rgba($color-secondary, .2)};
  --dots-color-active: #{$color-secondary};
  $element: &;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: none;
  min-width: 0;

  &--nav-overlay,
  &--arrows-overlay {
    --arrow-color: #{$color-white};
  }

  &--nav-overlay,
  &--dots-overlay {
    --dots-color: #{rgba($color-white, .3)};
    --dots-color-active: #{$color-white};
  }

  &__slides {
    height: 100%;
    width: 100%;
    position: relative;
    z-index: 1;
    overflow: hidden;

    .slick-list,
    .slick-track {
      height: 100%;
      width: 100%;
      display: flex !important;
    }

    .slick-slide {
      position: relative;
      height: inherit !important;
    }
  }

  &__slide {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &__image {
    @include backgroundImage;
  }

  &__dots {
    @include useCarouselVariants(dots-inline) {
      @include interpolate((padding-top, padding-bottom), 5px, 10px);
    }

    @include useCarouselVariants(dots-overlay) {
      @include interpolate((padding-top, padding-bottom), 10px, 20px);
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      z-index: 5;
    }

    .slick-dots {
      display: flex;
      align-items: center;
      justify-content: center;

      li {
        display: inline-block;
        @include interpolate(padding, 3px, 6px);
        line-height: 0;
        cursor: pointer;
        opacity: 1;
        @include transition(opacity);

        &.slick-active button {
          background-color: var(--dots-color-active);
        }

        button {
          @include interpolate((width, height), 4px, 8px);
          text-indent: -9999px;
          background-color: var(--dots-color);
          border-radius: 50%;
          cursor: pointer;
          @include transition(background-color);
        }

        &:hover {
          opacity: .6;
        }
      }
    }
  }

  &__arrows {
    @include useCarouselVariants(arrows-inline) {
      @include interpolate((padding-top, padding-bottom), 5px, 10px);
    }
  }

  &__arrow {
    opacity: 1;
    @include transition(opacity);

    @include useCarouselVariants(arrows-overlay) {
      @include interpolate(padding, 10px, 20px);
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: 5;
    }

    &:hover {
      opacity: .6;
    }

    &__icon {
      @include interpolate(height, 10px, 20px);
      @include interpolate(width, 8px, 16px);
      line-height: 0;

      @include useCarouselVariants(arrows-overlay) {
        @include interpolate(height, 15px, 30px);
        @include interpolate(width, 10px, 20px);
      }
    }

    svg {
      height: 100%;
      width: 100%;
      fill: var(--arrow-color);

      * {
        fill: inherit;
      }
    }

    &--prev {
      @include useCarouselVariants(arrows-overlay) {
        left: 0;
        @include interpolate(left, 5px, 10px);
      }
    }

    &--next {
      @include useCarouselVariants(arrows-overlay) {
        right: 0;
        @include interpolate(right, 5px, 10px);
      }
    }
  }

  &__arrow,
  &__dots {
    display: inline-block;
    z-index: 2;
  }

  &__nav {
    @include interpolate((padding-top, padding-bottom), 5px, 10px);
    display: flex;
    justify-content: center;
    align-items: center;
    @include interpolate((margin-left, margin-right), -3px, -6px);


    @include useCarouselVariants(nav-overlay) {
      position: absolute;
      bottom: 0;
      @include interpolate(bottom, 5px, 10px);
      left: 50%;
      transform: translateX(-50%);
      z-index: 5;
    }

    &>* {
      @include interpolate((margin-left, margin-right), 3px, 6px);
    }
  }

  &__nav &__dots {
    padding-top: 0;
    padding-bottom: 0;
  }

  &__video {
    &__inner {
      position: relative;
      height: 100%;
      width: 100%;

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        height: 100%;
        width: 100%;
      }
    }
  }
}