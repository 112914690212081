$base-class: '.mol-UnitTypeInformation';

@mixin useUnitTypeInformationVariants($variant) {
  @include useVariants($base-class, $variant) {
    @content;
  }
}

#{$base-class} {
  --label-icon-color: #{$color-secondary};
  @include sectionSpacing;

  .mol-HeroBasic+& {
    padding-top: 0;
  }

  &__image {
    @include aspectRatio(4, 3);
    @include interpolate(margin-bottom, 25px, 50px);
    @include backgroundImage(contain);

    @include breakpoint(medium) {
      margin-bottom: 0;
    }
  }

  &__content {
    align-self: center;
  }

  &__heading {
    @include font-h3;
  }

  &__copy {
    @include richText;
    @include interpolate(margin-top, 10px, 20px);

    p {
      @include font-body-small;
    }
  }

  &__labels {
    @include interpolate(margin-top, 10px, 20px);
    @include interpolate((margin-left, margin-right), -5px, -10px);
    @include flexbox(baseline, flex-start);
  }

  &__label {
    @include font-body-xsmall;
    @include flexbox(center, flex-start);
    @include interpolate((padding-left, padding-right), 5px, 10px);

    &+& {
      @include interpolate(margin-top, 3px, 6px);
    }

    &__icon {
      @include interpolate(margin-right, 5px, 10px);
      @include interpolate((width, height), 12px, 24px);
      display: block;

      svg {
        fill: var(--label-icon-color);
        height: 100%;
        width: 100%;

        * {
          fill: inherit;
        }
      }
    }

    &__text {
      display: block;
    }
  }
}