/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote {

  &:before,
  &:after {
    content: '';
    content: none;
  }
}

q {

  &:before,
  &:after {
    content: '';
    content: none;
  }
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

a {
  text-decoration: none;
  color: inherit;
  outline: none;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

button {
  outline: none;
  border: 0;
  background: none;
  padding: 0;
  font-size: inherit;
  line-height: inherit;
  font-family: inherit;
  font-weight: inherit;
  border-radius: 0;
  color: inherit;
  text-transform: inherit;
}

button,
a {
  cursor: pointer;
}

button::-moz-focus-inner {
  border: 0;
}

hr {
  border: none;
}

img {
  max-width: 100%;
  height: auto;
}

[role='button']:focus {
  outline: none;
}

// Slick slider reset

.slick-slide {
  outline: none;
  -webkit-backface-visibility: hidden;
}

.slick-hidden {
  display: none !important;
}

video {
  max-width: 100%;
}

// Forms
input,
textarea,
select {
  outline: none;
  border: none;
  -webkit-appearance: none;
  resize: none;
  background: none;
  font-size: inherit;
  font-family: inherit;
  font-weight: inherit;
  color: inherit;
  line-height: normal;
  padding: 0;
  border: none;
  box-shadow: none !important;

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active,
  &:-webkit-autofill::first-line {
    transition: background-color 99999s ease-in-out 0s;
    -webkit-text-fill-color: inherit;
    font-size: inherit;
    font-family: inherit;
    font-weight: inherit;
    color: inherit;
  }

  &::-ms-clear {
    display: none;
  }

  &::placeholder {
    color: inherit;
    opacity: 0.5;
  }
}

input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  -webkit-appearance: none;
}