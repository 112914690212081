/*--------------------------------------------------------------
# Variables
-------------------------------------------*/

/* Colors
-------------------------------------------*/

$color-neutral-darkest: #231f20;
$color-neutral-darker: #33322d;
$color-neutral-dark: #545247;
$color-neutral: #cfcbbc;
$color-neutral-medium: #e0dccd;
$color-neutral-light: #f5f2e5;
$color-neutral-lightest: #faf8f2;

$color-error: #bd401a;

$color-green-dark: #36401f;
$color-green: #636d4d;
$color-gold: #947d37;

$color-primary-dark: $color-green-dark;
$color-primary: $color-green;
$color-secondary: $color-gold;

$color-white: $color-neutral-lightest;
$color-black: $color-primary-dark;

/* Breakpoints
-------------------------------------------*/
$bp-xxs: 400px;
$bp-xs: 560px;
$bp-s: 768px;
$bp-m: 1024px;
$bp-d: 1280px;
$bp-l: 1440px;
$bp-xl: 1680px;
$bp-xxl: 1920px;

/* Z-Index
-------------------------------------------*/
$zIndex-header: 100;
$zIndex-modal-form: 300;
$zIndex-modal-unit: 300;
$zIndex-loader: 1000;