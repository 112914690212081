$base-class: '.mol-ListingUnitTypes';

@mixin useListingUnitTypesVariants($variant) {
  @include useVariants($base-class, $variant) {
    @content;
  }
}

#{$base-class} {
  @include sectionSpacing;
  @include useDisableSpacing;

  .grid-container {
    overflow: visible;
  }

  &__introduction {
    @include interpolate(margin-bottom, 35px, 70px);
  }

  &__label {
    @include font-h6;
    @include interpolate(margin-bottom, 10px, 20px);
  }

  &__heading {
    @include font-h2;
    max-width: 15em;
  }

  &__copy {
    @include font-body;
    @include interpolate(margin-top, 15px, 30px);
    max-width: 40em;
  }

  &__button {
    @include interpolate(margin-top, 20px, 40px);
  }

  &__navigation {
    @include interpolate(margin-bottom, 25px, 50px);
  }

  &__filters-wrapper {
    @include flexbox(center, flex-start);
  }

  &__filters {
    @include flexbox(center, flex-start);

    &__heading {
      @include interpolate(margin-right, 10px, 20px);
      @include font-body;
    }

    &__dropdown {
      @include interpolate((padding-top, padding-bottom), 8px, 16px);
      @include interpolate(padding-left, 10px, 20px);
      @include interpolate(padding-right, 20px, 40px);
      @include transition(opacity, .2s);
      @include font-body-small;
      @include interpolate(background-size, 8px, 12px);
      line-height: 1;
      border-radius: 2px;
      background-image: url('/built/icons/arrow-down.svg');
      background-position: right 1em center;
      background-repeat: no-repeat;
      border: 1px solid $color-black;
    }
  }

  &__items {
    @include interpolate(margin-top, -25px, -50px);
  }

  &__item {
    @include interpolate(margin-top, 25px, 50px);
    @include flexbox(stretch);

    &>* {
      width: 100%;
    }
  }
}