$base-class: '.mol-ContentTextTypeFour';

@mixin useContentTextTypeFourVariants($variant) {
  @include useVariants($base-class, $variant) {
    @content;
  }
}

#{$base-class} {
  @include sectionSpacing;
  @include useDisableSpacing;

  &__label {
    @include font-h6;
    @include interpolate(margin-bottom, 10px, 20px);
  }

  &__heading {
    @include font-h2;
    @include interpolate(margin-bottom, 20px, 40px);
    max-width: 16em;
  }

  &__copy {
    @include richText;
  }
}