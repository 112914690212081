$base-class: '.mol-ListingFeatures';

@mixin useListingFeaturesVariants($variant) {
  @include useVariants($base-class, $variant) {
    @content;
  }
}

#{$base-class} {
  @include sectionSpacing;
  @include useDisableSpacing;

  &__introduction {
    @include interpolate(margin-bottom, 35px, 70px);
  }

  &__label {
    @include font-h6;
    @include interpolate(margin-bottom, 10px, 20px);
  }

  &__heading {
    @include font-h2;
    max-width: 15em;
  }

  &__copy {
    @include font-body;
    @include interpolate(margin-top, 15px, 30px);
    max-width: 40em;
  }

  &__button {
    @include interpolate(margin-top, 20px, 40px);
  }

  &__items {
    @include flexbox(stretch);
  }

  &__item {
    @include flexbox(stretch);
    padding: 1px;
    flex: 0 1 33.3333333%;

    &>* {
      width: 100%;
      flex: 1;
    }

    @include breakpoint(medium, "max") {
      flex: 0 1 50%;
    }

    @include breakpoint(xsmall, "max") {
      flex: 0 1 100%;
    }
  }
}