$font-primary: "Commuters Sans";
$font-secondary: "Ivy Mode";
$font-weight-thin: 100;
$font-weight-extralight: 200;
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$font-weight-extrabold: 800;
$font-weight-black: 900;

@include font-face($font-primary, "CommutersSans/CommutersSans-Regular", $font-weight-regular);
@include font-face($font-primary, "CommutersSans/CommutersSans-RegularItalic", $font-weight-regular, italic);
// @include font-face($font-primary, "CommuterSans/CommuterSans-Bold", $font-weight-bold);
// @include font-face($font-primary, "CommuterSans/CommuterSans-BoldItalic", $font-weight-bold, italic);

@include font-face($font-secondary, "IvyMode/IvyMode-Regular", $font-weight-regular);
@include font-face($font-secondary, "IvyMode/IvyMode-Italic", $font-weight-regular, italic);

// font styles that will be used on the site
@mixin font-h0 {
  @include font($font-secondary, $font-weight-regular, 50px, 76px, 1.05, normal, 0);
}

@mixin font-h1 {
  @include font($font-secondary, $font-weight-regular, 40px, 64px, 1.1, normal, 0);
}

@mixin font-h2 {
  @include font($font-secondary, $font-weight-regular, 30px, 44px, 1.2, normal, 0);
}

@mixin font-h3 {
  @include font($font-secondary, $font-weight-regular, 26px, 36px, 1.2, normal, 0);
}

@mixin font-h4 {
  @include font($font-secondary, $font-weight-regular, 20px, 30px, 1.2, normal, 0);
}

@mixin font-h5 {
  @include font($font-secondary, $font-weight-regular, 18px, 24px, 1.2, normal, 0);
}

@mixin font-h6 {
  @include font($font-primary, $font-weight-regular, 12px, 16px, 1.2, normal, .1em);
  text-transform: uppercase;
}

@mixin font-body-large {
  @include font($font-primary, $font-weight-regular, 18px, 24px, 1.5, normal, 0);
}

@mixin font-body {
  @include font($font-primary, $font-weight-regular, 15px, 19px, 1.5, normal, 0);
}

@mixin font-body-small {
  @include font($font-primary, $font-weight-regular, 12px, 16px, 1.5, normal, 0);
}

@mixin font-body-xsmall {
  @include font($font-primary, $font-weight-regular, 10px, 14px, 1.5, normal, 0);
}

html {
  -webkit-text-size-adjust: 100%;
}

body#website {
  text-rendering: optimizeLegibility;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @include font-body;
  line-height: 1;
  color: $color-black;
}

// sitewide type styles
@mixin link($theme: dark) {
  @include transition(color, 0.3s, ease);

  &:hover {
    color: $color-primary;

    @if $theme==light {
      color: rgba($color-white, .7);
    }
  }
}

a {
  color: inherit;
  text-decoration: none;
}


strong,
b {
  font-weight: $font-weight-bold;
}

em,
i {
  font-style: italic;
}

@mixin list($type: unordered,
  $margin: .5em,
  $size: regular,
  $bulletColor: $color-black) {
  li {
    @if $size=='small' {
      @include interpolate(padding-left, 8px, 14px);
      @include font-body-small;
    }

    @else {
      @include interpolate(padding-left, 20px, 30);
      @include font-body;
    }

    position: relative;

    &:not(:last-child) {
      margin-bottom: $margin;
    }

    &:before {
      position: absolute;
      left: 0;
      color: $bulletColor;
    }
  }

  &>li {
    li {
      &:first-child {
        margin-top: $margin;
      }

      &:before {
        left: 0;
        color: $bulletColor;
      }
    }
  }

  // Unordered
  @if $type==unordered {
    li:before {
      content: '\02022';

      @if $size=='small' {
        transform: scale(0.9);
      }

      @else {
        transform: scale(1.2);
      }
    }

    &>li {
      &:before {
        transform-origin: center center;
      }
    }
  }

  // Ordered
  @if $type==ordered {
    counter-reset: section;

    li:before {
      content: '';
      transform: none;
      font-weight: $font-weight-bold;
    }

    &>li {
      counter-increment: section;

      &:before {
        content: counter(section) '. ';
      }

      // sub-list
      ol {
        counter-reset: children;

        li {
          counter-increment: children;

          &:before {
            content: counter(children, lower-alpha) '. ';
          }
        }
      }
    }
  }
}

@mixin richText {
  &>* {
    display: block;
    margin-top: 2em;
    margin-bottom: 0.75em;

    @include breakpoint("xsmall", "max") {
      margin-top: 2em;
      margin-bottom: 0.5em;
    }
  }

  &>p,
  &>blockquote {
    display: block;
    margin-top: .5em;
    margin-bottom: 1.5em;
  }

  &>ul,
  &>ol {
    margin-top: 1.75em;
    margin-bottom: 1.75em;

    @include breakpoint("xsmall", "max") {
      margin-top: 1.25em;
      margin-bottom: 1.25em;
    }
  }

  &>* {
    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &>h4,
  &>h5,
  &>h6 {
    margin-top: 1.5em;
    margin-bottom: .5em;

  }

  h1 {
    @include font-h2;
  }

  h2 {
    @include font-h3;
  }

  h3 {
    @include font-h4;
  }

  h4 {
    @include font-h5;
  }

  h5 {
    @include font-h6;
  }

  h6 {
    @include font-body-small;
    font-weight: $font-weight-bold;
  }

  small {
    @include font-body-small();
  }

  p {
    @include font-body;
  }

  ul {
    @include list;
  }

  ol {
    @include list(ordered);
  }

  blockquote {
    @include font-h4;
  }

  &>img,
  &>figure {
    @include font-body-small();
    max-width: 100%;
    width: 100%;
    margin-top: 5em;
    margin-bottom: 5em;

    @include breakpoint("xsmall", "max") {
      margin-top: 4em;
      margin-bottom: 4em;
    }

    figcaption {
      margin-top: 1em;
    }
  }

  a {
    @include link;
  }
}